import React from "react";
import "./BrideGroom.scss";

import Dat from "../../images/Dat.jpg";
import Quynh from "../../images/Quynh.jpg";

function BrideGroom() {
  return (
    <section className="bride-groom">
      <div className="avatar">
        <img src={Dat} alt="Dat" />
      </div>
      <div className="name">
        <div>Quốc Đạt</div>
        <div>&</div>
        <div>Tố Quỳnh</div>
      </div>
      <div className="avatar">
        <img src={Quynh} alt="Quynh" />
      </div>
    </section>
  );
}

export default BrideGroom;
