import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Link } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';

import './MyGalleryNew.scss';
import { useTranslation } from 'react-i18next';

import FlowerLine from '../../images/flower_line.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Album from './Album';

const DownloadButton = ({ image }) => {
  const handleClick = () => {
    const fileName = image.substring(image.lastIndexOf('/') + 1);
    saveAs(image, fileName);
  };

  return (
    <button
      type='button download-btn'
      aria-label='Download'
      className='yarl__button'
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faDownload} style={{ height: 19 }} />
    </button>
  );
};

function MyGalleryNew() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showLightBox, setShowLightBox] = useState(false);
  const { t } = useTranslation();

  const mapImageAndThumbnail = (album) => {
    return album.images.map((image) => {
      const imageName = image.substring(image.lastIndexOf('/') + 1);
      const thumbnail =
        album.thumbnails.find((thumbnail) => thumbnail.includes(imageName)) ||
        image;

      return { src: image, thumbnail };
    });
  };

  const album = mapImageAndThumbnail(Album);

  const toggleLightBox = (index) => {
    setCurrentIndex(index);
    setShowLightBox(!showLightBox);
  };

  return (
    <section id='gallery' className='gallery'>
      <img className='flower-line' src={FlowerLine} alt='' />
      <div className='gallery-heading'>{t('gallery.title')}</div>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4 }}>
        <Masonry className='masonry' columnsCount={4} gutter='10px'>
          {album.map((photo, index) => (
            <LazyLoadImage
              key={index}
              src={photo.thumbnail}
              width={'auto'}
              height={'auto'}
              alt='Dat Quynh Wedding'
              onClick={() => toggleLightBox(index)}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>

      <Lightbox
        open={showLightBox}
        close={() => setShowLightBox(false)}
        index={currentIndex}
        slides={album.map((photo) => {
          return { src: photo.src };
        })}
        plugins={[Zoom]}
        toolbar={{
          buttons: [
            <DownloadButton
              key='download'
              image={Album.images[currentIndex]}
            />,
            'close',
          ],
        }}
        on={{
          view: (index) => {
            setCurrentIndex(index);
          },
        }}
      />

      <div className='button-group'>
        <Link to={`gallery/wedding`}>
          <button onClick={() => {}}>{t('gallery.buttonWedding')}</button>
        </Link>
        <Link to={`gallery/prewedding`}>
          <button onClick={() => {}}>{t('gallery.buttonPreWedding')}</button>
        </Link>
        <Link to={`gallery/usa`}>
          <button onClick={() => {}}>{t('gallery.buttonUSA')}</button>
        </Link>
      </div>
    </section>
  );
}

export default MyGalleryNew;
