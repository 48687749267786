const album = {
  title: "traditionalCeremony",
  images: [
    "https://i.ibb.co/b2QrXqd/IMG-4865.jpg",
    "https://i.ibb.co/PwGtrBt/IMG-4856.jpg",
    "https://i.ibb.co/Jpyn5hD/IMG-4870.jpg",
    "https://i.ibb.co/Sd9b9w5/IMG-4872.jpg",
    "https://i.ibb.co/sH23QNk/IMG-4874.jpg",
    "https://i.ibb.co/MRk5fSZ/IMG-4876.jpg",
    "https://i.ibb.co/m6HBcYm/IMG-4882.jpg",
    "https://i.ibb.co/QFr9s3f/IMG-4879.jpg",
    "https://i.ibb.co/wg8H7rM/PSL21482.jpg",
    "https://i.ibb.co/KsbrGrc/PSL21479.jpg",
    "https://i.ibb.co/qBF2QNK/DSCF0160.jpg",
    "https://i.ibb.co/kQDkY6v/IMG-4888.jpg",
    "https://i.ibb.co/v48M1k8/IMG-4885.jpg",
    "https://i.ibb.co/bFmMGWM/IMG-4899.jpg",
    "https://i.ibb.co/Z61JdPN/IMG-4905.jpg",
    "https://i.ibb.co/g6XSVzy/IMG-4907.jpg",
    "https://i.ibb.co/TT3CpNw/IMG-4918.jpg",
    "https://i.ibb.co/sFwcqV2/IMG-4916.jpg",
    "https://i.ibb.co/9TYWsy7/IMG-4922.jpg",
    "https://i.ibb.co/DzwCWbj/DSCF0187.jpg",
    "https://i.ibb.co/0n1hSJQ/IMG-4926.jpg",
    "https://i.ibb.co/bzCHmYH/IMG-4935.jpg",
    "https://i.ibb.co/0mms9qf/IMG-4937.jpg",
    "https://i.ibb.co/FhBPnPg/IMG-4942.jpg",
    "https://i.ibb.co/MV5g0F9/IMG-4949.jpg",
    "https://i.ibb.co/6YwcQv6/DSCF0196.jpg",
    "https://i.ibb.co/tqXDCRZ/DSCF0202.jpg",
    "https://i.ibb.co/CQDgQKS/IMG-4952.jpg",
    "https://i.ibb.co/1zPN4WY/DSCF0212.jpg",
    "https://i.ibb.co/V9LkvLM/IMG-4960.jpg",
    "https://i.ibb.co/ykQ1mWz/IMG-4970.jpg",
    "https://i.ibb.co/C9yMFND/IMG-4967.jpg",
    "https://i.ibb.co/NLP18nK/IMG-4973.jpg",
    "https://i.ibb.co/LdvDtXc/IMG-4976.jpg",
    "https://i.ibb.co/8rqFgrS/IMG-4979.jpg",
    "https://i.ibb.co/pv9qvWm/DSCF0223.jpg",
    "https://i.ibb.co/2cbJj6H/DSCF0226.jpg",
    "https://i.ibb.co/hMtN4p6/DSCF0228.jpg",
    "https://i.ibb.co/qmDgjNZ/DSCF0231.jpg",
    "https://i.ibb.co/CpjskBk/DSCF0235.jpg",
    "https://i.ibb.co/JpJ2mcc/DSCF0243.jpg",
    "https://i.ibb.co/qYWHzWL/DSCF0247.jpg",
    "https://i.ibb.co/Gn4WVLz/DSCF0251.jpg",
    "https://i.ibb.co/N3ppZc9/DSCF0261.jpg",
    "https://i.ibb.co/c6P482S/IMG-4988.jpg",
    "https://i.ibb.co/mvS3PkN/IMG-4991.jpg",
    "https://i.ibb.co/S5N01RS/IMG-4994.jpg",
    "https://i.ibb.co/ZNw5fDF/IMG-4998.jpg",
    "https://i.ibb.co/6HWW3YQ/IMG-5004.jpg",
    "https://i.ibb.co/fqj8pjQ/IMG-5012.jpg",
    "https://i.ibb.co/N1nnvLq/IMG-5019.jpg",
    "https://i.ibb.co/vJG9fbW/DSCF0271.jpg",
    "https://i.ibb.co/hBPTJNC/IMG-5022.jpg",
    "https://i.ibb.co/sjrxRtH/DSCF0275.jpg",
    "https://i.ibb.co/8mWC4jX/DSCF0278.jpg",
    "https://i.ibb.co/0j93XQ5/DSCF0279.jpg",
    "https://i.ibb.co/JjBjYyT/DSCF0283.jpg",
    "https://i.ibb.co/8b1xQZv/DSCF0286.jpg",
    "https://i.ibb.co/MhWP83V/DSCF0296.jpg",
    "https://i.ibb.co/9pw3Hcj/IMG-5029.jpg",
    "https://i.ibb.co/bWDgvm0/IMG-5025.jpg",
    "https://i.ibb.co/LYscfC6/IMG-5034.jpg",
    "https://i.ibb.co/0D6NR4g/IMG-5048.jpg",
    "https://i.ibb.co/YQjjNCv/IMG-5055.jpg",
    "https://i.ibb.co/PNVKWP1/IMG-5051.jpg",
    "https://i.ibb.co/hDsC3t3/IMG-5060.jpg",
    "https://i.ibb.co/j4KR10W/IMG-5061.jpg",
    "https://i.ibb.co/hZVGcFT/PSL21491.jpg",
    "https://i.ibb.co/Hdjmgmh/PSL21498.jpg",
    "https://i.ibb.co/j4h6GYr/DSCF0303.jpg",
    "https://i.ibb.co/6yd2Jp0/DSCF0301.jpg",
    "https://i.ibb.co/khzXk0n/DSCF0312.jpg",
    "https://i.ibb.co/bgY2rSX/DSCF0305.jpg",
    "https://i.ibb.co/31Z74Vp/DSCF0317.jpg",
    "https://i.ibb.co/7r73rYB/DSCF0320.jpg",
    "https://i.ibb.co/gm69yy3/DSCF0324.jpg",
    "https://i.ibb.co/F3kJmrY/DSCF0322.jpg",
    "https://i.ibb.co/Dr80MH3/DSCF0328.jpg",
    "https://i.ibb.co/xYbFPKc/DSCF0333.jpg",
  ],

  thumbnails: [
    "https://i.ibb.co/qW7RpX1/IMG-4865.jpg",
    "https://i.ibb.co/gzZ6rs6/IMG-4856.jpg",
    "https://i.ibb.co/LgJSnGV/IMG-4870.jpg",
    "https://i.ibb.co/5nfmfhG/IMG-4872.jpg",
    "https://i.ibb.co/GT0Pk8B/IMG-4874.jpg",
    "https://i.ibb.co/Zhz1SXG/IMG-4876.jpg",
    "https://i.ibb.co/dGQc53S/IMG-4882.jpg",
    "https://i.ibb.co/WWgnS46/IMG-4879.jpg",
    "https://i.ibb.co/W5790pg/PSL21482.jpg",
    "https://i.ibb.co/kD5SySV/PSL21479.jpg",
    "https://i.ibb.co/8M4QZrq/DSCF0160.jpg",
    "https://i.ibb.co/GcMBg3N/IMG-4888.jpg",
    "https://i.ibb.co/23BpqdB/IMG-4885.jpg",
    "https://i.ibb.co/m0TVPDV/IMG-4899.jpg",
    "https://i.ibb.co/QFjc9RX/IMG-4905.jpg",
    "https://i.ibb.co/XVd78SX/IMG-4907.jpg",
    "https://i.ibb.co/rtrWTXQ/IMG-4918.jpg",
    "https://i.ibb.co/y8yKqQX/IMG-4916.jpg",
    "https://i.ibb.co/DCKQ5wZ/IMG-4922.jpg",
    "https://i.ibb.co/djk2McH/DSCF0187.jpg",
    "https://i.ibb.co/tHn8tC3/IMG-4926.jpg",
    "https://i.ibb.co/KFfNhBN/IMG-4935.jpg",
    "https://i.ibb.co/ydd4k08/IMG-4937.jpg",
    "https://i.ibb.co/dfQh7hG/IMG-4942.jpg",
    "https://i.ibb.co/9T3p5QG/IMG-4949.jpg",
    "https://i.ibb.co/wygZtJD/DSCF0196.jpg",
    "https://i.ibb.co/K2wWynN/DSCF0202.jpg",
    "https://i.ibb.co/2dHxd3p/IMG-4952.jpg",
    "https://i.ibb.co/y4ZCxjm/DSCF0212.jpg",
    "https://i.ibb.co/vqcMJcL/IMG-4960.jpg",
    "https://i.ibb.co/3FCq5Bx/IMG-4970.jpg",
    "https://i.ibb.co/B6JrRdF/IMG-4967.jpg",
    "https://i.ibb.co/THSKVqt/IMG-4973.jpg",
    "https://i.ibb.co/QvX7N3T/IMG-4976.jpg",
    "https://i.ibb.co/QPLsvP1/IMG-4979.jpg",
    "https://i.ibb.co/gMBYMvn/DSCF0223.jpg",
    "https://i.ibb.co/VMK6vN5/DSCF0226.jpg",
    "https://i.ibb.co/thW6vyG/DSCF0228.jpg",
    "https://i.ibb.co/HGKP4tJ/DSCF0231.jpg",
    "https://i.ibb.co/1YCJDZD/DSCF0235.jpg",
    "https://i.ibb.co/n1Jf6ww/DSCF0243.jpg",
    "https://i.ibb.co/Df8BN8P/DSCF0247.jpg",
    "https://i.ibb.co/LYXxCWV/DSCF0251.jpg",
    "https://i.ibb.co/Dbkk509/DSCF0261.jpg",
    "https://i.ibb.co/dPS8pkw/IMG-4988.jpg",
    "https://i.ibb.co/s5tZXnW/IMG-4991.jpg",
    "https://i.ibb.co/ZGdKwW9/IMG-4994.jpg",
    "https://i.ibb.co/QXqLJwT/IMG-4998.jpg",
    "https://i.ibb.co/F5DDS6p/IMG-5004.jpg",
    "https://i.ibb.co/gmkvTk6/IMG-5012.jpg",
    "https://i.ibb.co/XykkQzP/IMG-5019.jpg",
    "https://i.ibb.co/K9knBYg/DSCF0271.jpg",
    "https://i.ibb.co/SthZ4gJ/IMG-5022.jpg",
    "https://i.ibb.co/6Y59wNB/DSCF0275.jpg",
    "https://i.ibb.co/S6hj7KJ/DSCF0278.jpg",
    "https://i.ibb.co/mCy7BSp/DSCF0279.jpg",
    "https://i.ibb.co/qYyYHW8/DSCF0283.jpg",
    "https://i.ibb.co/bFcvG9w/DSCF0286.jpg",
    "https://i.ibb.co/1nhRswq/DSCF0296.jpg",
    "https://i.ibb.co/10sJG7x/IMG-5029.jpg",
    "https://i.ibb.co/FxCbY3r/IMG-5025.jpg",
    "https://i.ibb.co/HB9bQrY/IMG-5034.jpg",
    "https://i.ibb.co/4mXynv6/IMG-5048.jpg",
    "https://i.ibb.co/FKd2Yy8/IMG-5051.jpg",
    "https://i.ibb.co/dMKKfTn/IMG-5055.jpg",
    "https://i.ibb.co/Gc7Fzbz/IMG-5060.jpg",
    "https://i.ibb.co/KyZw34L/IMG-5061.jpg",
    "https://i.ibb.co/BrBhG2Y/PSL21491.jpg",
    "https://i.ibb.co/L534p4N/PSL21498.jpg",
    "https://i.ibb.co/12ZvKNm/DSCF0303.jpg",
    "https://i.ibb.co/8mhJ4Rg/DSCF0301.jpg",
    "https://i.ibb.co/VvbTrDy/DSCF0312.jpg",
    "https://i.ibb.co/C54MnkQ/DSCF0305.jpg",
    "https://i.ibb.co/4Wr7KC8/DSCF0317.jpg",
    "https://i.ibb.co/44Bb4mC/DSCF0320.jpg",
    "https://i.ibb.co/dj7MBBr/DSCF0324.jpg",
    "https://i.ibb.co/54XvG0j/DSCF0322.jpg",
    "https://i.ibb.co/WVPmz4S/DSCF0328.jpg",
    "https://i.ibb.co/PMyD3sb/DSCF0333.jpg",
  ],
};

export default album;
