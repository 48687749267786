/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './NavigationBar.scss';
import { useTranslation } from 'react-i18next';

const NavigationBar = ({ type, isActived }) => {
  const { t } = useTranslation();

  if (type === 'secondary') {
    return (
      <nav className={`${isActived ? 'nav--active' : ''}`}>
        <a href='#home'>{t('nav.home')}</a>
        <a href='/gallery/wedding'>{t('nav.wedding')}</a>
        <a href='/gallery/prewedding'>{t('nav.prewedding')}</a>
        <a href='/gallery/usa'>{t('nav.usa')}</a>
        <a href='/'>{t('nav.main')}</a>
      </nav>
    );
  }

  return (
    <nav className={`${isActived ? 'nav--active' : ''}`}>
      <a href='#home'>{t('nav.home')}</a>
      <a href='#story'>{t('nav.story')}</a>
      <a href='#gallery'>{t('nav.gallery')}</a>
      <a href='#video'>{t('nav.video')}</a>
    </nav>
  );
};

export default NavigationBar;
