const album = {
  title: "brideAndGroom",
  images: [
    "https://i.ibb.co/dK3QRyM/DSCF0335.jpg",
    "https://i.ibb.co/5hmNg8N/DSCF0338.jpg",
    "https://i.ibb.co/hsBtF9C/DSCF0341.jpg",
    "https://i.ibb.co/vsQsqbr/DSCF0349.jpg",
    "https://i.ibb.co/zZ0T4NF/DSCF0354.jpg",
    "https://i.ibb.co/8jkrzvX/DSCF0357.jpg",
    "https://i.ibb.co/PDLs0Hs/DSCF0362.jpg",
    "https://i.ibb.co/j4V8Qj9/DSCF0364.jpg",
    "https://i.ibb.co/G7Mw8S5/IMG-5078.jpg",
    "https://i.ibb.co/9tcyMYs/DSCF0370.jpg",
    "https://i.ibb.co/D750bZf/DSCF0373.jpg",
    "https://i.ibb.co/NFNTk1g/TIM07904.jpg",
    "https://i.ibb.co/Pjz8FzL/DSCF0408.jpg",
    "https://i.ibb.co/K0YJ17X/DSCF0422.jpg",
    "https://i.ibb.co/X5gYctx/DSCF0427.jpg",
    "https://i.ibb.co/G5nB0xY/PSL21517.jpg",
    "https://i.ibb.co/w06t9YZ/PSL21519.jpg",
    "https://i.ibb.co/hFDgpdG/PSL21525.jpg",
    "https://i.ibb.co/WHB08fd/PSL21526.jpg",
    "https://i.ibb.co/9GjWw6Y/PSL21547.jpg",
    "https://i.ibb.co/GtkGy7H/PSL21533.jpg",
    "https://i.ibb.co/D5JBbq7/PSL21536.jpg",
    "https://i.ibb.co/sJV4cpX/PSL21567.jpg",
    "https://i.ibb.co/pKzZGXW/PSL21571.jpg",
    "https://i.ibb.co/dfRMTSp/PSL21578.jpg",
    "https://i.ibb.co/ZzVRccd/PSL21579.jpg",
    "https://i.ibb.co/bLF1cgp/PSL21582.jpg",
    "https://i.ibb.co/BB8TCWD/PSL21584.jpg",
    "https://i.ibb.co/YRhVFZF/PSL21587.jpg",
    "https://i.ibb.co/YfQt490/PSL21590.jpg",
    "https://i.ibb.co/0G4rb4g/PSL21593.jpg",
    "https://i.ibb.co/2PHt7yf/PSL21597.jpg",
    "https://i.ibb.co/DkyLJVF/PSL21602.jpg",
    "https://i.ibb.co/mHBx21B/PSL21603.jpg",
    "https://i.ibb.co/vqGscfZ/PSL21606.jpg",
    "https://i.ibb.co/Sx1ZqC6/PSL21609.jpg",
    "https://i.ibb.co/HYksrNf/PSL21614.jpg",
    "https://i.ibb.co/yy3fK5T/PSL21615.jpg",
    "https://i.ibb.co/GcCdW8k/PSL21617.jpg",
    "https://i.ibb.co/tJ5HnwW/PSL21622.jpg",
    "https://i.ibb.co/WfNv5LX/PSL21625.jpg",
    "https://i.ibb.co/vs06JyH/PSL21636.jpg",
    "https://i.ibb.co/ZSFwDQQ/TIM07939.jpg",
    "https://i.ibb.co/GFQR5s1/TIM07949.jpg",
    "https://i.ibb.co/wsFzXkr/TIM07952.jpg",
    "https://i.ibb.co/7G9hqH7/DSCF0457.jpg",
    "https://i.ibb.co/RzsZGjZ/DSCF0461.jpg",
  ],

  thumbnails: [
    "https://i.ibb.co/dK3QRyM/DSCF0335.jpg",
    "https://i.ibb.co/5hmNg8N/DSCF0338.jpg",
    "https://i.ibb.co/hsBtF9C/DSCF0341.jpg",
    "https://i.ibb.co/vsQsqbr/DSCF0349.jpg",
    "https://i.ibb.co/zZ0T4NF/DSCF0354.jpg",
    "https://i.ibb.co/8jkrzvX/DSCF0357.jpg",
    "https://i.ibb.co/PDLs0Hs/DSCF0362.jpg",
    "https://i.ibb.co/j4V8Qj9/DSCF0364.jpg",
    "https://i.ibb.co/9tcyMYs/DSCF0370.jpg",
    "https://i.ibb.co/D750bZf/DSCF0373.jpg",
    "https://i.ibb.co/G7Mw8S5/IMG-5078.jpg",
    "https://i.ibb.co/NFNTk1g/TIM07904.jpg",
    "https://i.ibb.co/Pjz8FzL/DSCF0408.jpg",
    "https://i.ibb.co/K0YJ17X/DSCF0422.jpg",
    "https://i.ibb.co/X5gYctx/DSCF0427.jpg",
    "https://i.ibb.co/9GjWw6Y/PSL21547.jpg",
    "https://i.ibb.co/7G9hqH7/DSCF0457.jpg",
    "https://i.ibb.co/RzsZGjZ/DSCF0461.jpg",
    "https://i.ibb.co/G5nB0xY/PSL21517.jpg",
    "https://i.ibb.co/w06t9YZ/PSL21519.jpg",
    "https://i.ibb.co/hFDgpdG/PSL21525.jpg",
    "https://i.ibb.co/WHB08fd/PSL21526.jpg",
    "https://i.ibb.co/GtkGy7H/PSL21533.jpg",
    "https://i.ibb.co/D5JBbq7/PSL21536.jpg",
    "https://i.ibb.co/sJV4cpX/PSL21567.jpg",
    "https://i.ibb.co/pKzZGXW/PSL21571.jpg",
    "https://i.ibb.co/dfRMTSp/PSL21578.jpg",
    "https://i.ibb.co/ZzVRccd/PSL21579.jpg",
    "https://i.ibb.co/bLF1cgp/PSL21582.jpg",
    "https://i.ibb.co/BB8TCWD/PSL21584.jpg",
    "https://i.ibb.co/YRhVFZF/PSL21587.jpg",
    "https://i.ibb.co/YfQt490/PSL21590.jpg",
    "https://i.ibb.co/0G4rb4g/PSL21593.jpg",
    "https://i.ibb.co/2PHt7yf/PSL21597.jpg",
    "https://i.ibb.co/DkyLJVF/PSL21602.jpg",
    "https://i.ibb.co/mHBx21B/PSL21603.jpg",
    "https://i.ibb.co/vqGscfZ/PSL21606.jpg",
    "https://i.ibb.co/Sx1ZqC6/PSL21609.jpg",
    "https://i.ibb.co/HYksrNf/PSL21614.jpg",
    "https://i.ibb.co/yy3fK5T/PSL21615.jpg",
    "https://i.ibb.co/GcCdW8k/PSL21617.jpg",
    "https://i.ibb.co/tJ5HnwW/PSL21622.jpg",
    "https://i.ibb.co/WfNv5LX/PSL21625.jpg",
    "https://i.ibb.co/vs06JyH/PSL21636.jpg",
    "https://i.ibb.co/ZSFwDQQ/TIM07939.jpg",
    "https://i.ibb.co/GFQR5s1/TIM07949.jpg",
    "https://i.ibb.co/wsFzXkr/TIM07952.jpg",
  ],
};

export default album;
