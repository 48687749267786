const album = {
  title: "album1",
  images: [
    "https://i.ibb.co/6gM6Wjf/TIM07867.jpg",
    "https://i.ibb.co/8rqFgrS/IMG-4979.jpg",
    "https://i.ibb.co/FbYhWnV/DSCF0012.jpg",
    "https://i.ibb.co/pPsXSTy/PSL21187.jpg",
    "https://i.ibb.co/VHmjZs3/IMG-5138.jpg",
    "https://i.ibb.co/b1tw57q/PSL21977.jpg",
    "https://i.ibb.co/3fGz9yc/IMG-5663.jpg",
    "https://i.ibb.co/qnj8Spv/IMG-5921.jpg",
  ],
  thumbnails: [
    "https://i.ibb.co/YQqyJqB/TIM07867.jpg",
    "https://i.ibb.co/n6crB1Q/IMG-4979.jpg",
    "https://i.ibb.co/dLcpW0g/DSCF0012.jpg",
    "https://i.ibb.co/tpQmN7G/PSL21187.jpg",
    "https://i.ibb.co/ZcFPxV4/IMG-5138.jpg",
    "https://i.ibb.co/rb7z1b8/PSL21977.jpg",
    "https://i.ibb.co/dGr5gXh/IMG-5663.jpg",
    "https://i.ibb.co/rMp1dg6/IMG-5921.jpg",
  ],
};

export default album;
