import Main from "./screens/Main/Main";
import "./App.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Gallery from "./screens/Gallery/Gallery";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },

  {
    path: "/gallery/:album",
    element: <Gallery />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
