import React from "react";
import { useTranslation } from "react-i18next";

import "./OurStory.scss";

function OurStory() {
  const { t } = useTranslation();

  return (
    <section id="story" className="our-story">
      <div className="our-story-overlay"></div>
      <div className="our-story-box">
        <div className="text-box">
          <div className="heading">{t("story.title")}</div>
          <p>
            {t("story.desc")} :"{">"}
          </p>
        </div>
      </div>
    </section>
  );
}

export default OurStory;
