import React from "react";

import Header from "../../components/Header/Header";
import BrideGroom from "../../components/BrideGroom/BrideGroom";
import MyGalleryNew from "../../components/MyGalleryNew/MyGalleryNew";
import OurStory from "../../components/OurStory/OurStory";
import StoryTimeline from "../../components/StoryTimeline/StoryTimeline";
import Menu from "../../components/Menu/Menu";
import Footer from "../../components/Footer/Footer";
import Welcome from "../../components/Welcome/Welcome";
import MyVideo from "../../components/MyVideo/MyVideo";

import "./Main.scss";

const Main = () => {
  return (
    <>
      <Menu />
      <Header />
      <main>
        <Welcome />
        <BrideGroom />
        <OurStory />
        <StoryTimeline />
        <MyGalleryNew />
        <MyVideo />
      </main>
      <Footer />
    </>
  );
};

export default Main;
