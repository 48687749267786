const album = {
  title: "dinner",
  images: [
    // "https://i.ibb.co/R2XL4W4/PSL22152.jpg",
    "https://i.ibb.co/Qk7k9QS/IMG-5704.jpg",
    "https://i.ibb.co/qjF8Bcy/PSL22158.jpg",
    "https://i.ibb.co/fYkQHBC/PSL22166.jpg",
    "https://i.ibb.co/kGKzTKF/DSCF0958.jpg",
    "https://i.ibb.co/R6DYc0j/DSCF0963.jpg",
    "https://i.ibb.co/525m2qc/DSCF0968.jpg",
    "https://i.ibb.co/jL7xPD1/IMG-5726.jpg",
    "https://i.ibb.co/NSDWHKK/DSCF0977.jpg",
    "https://i.ibb.co/WcH585y/TIM07987.jpg",
    "https://i.ibb.co/BP0qZtY/IMG-5729.jpg",
    "https://i.ibb.co/09Nms7q/DSCF0980.jpg",
    "https://i.ibb.co/ct4zvNj/DSCF0983.jpg",
    "https://i.ibb.co/xq1TVXG/IMG-5737.jpg",
    "https://i.ibb.co/Yt7nKT5/DSCF0991.jpg",
    "https://i.ibb.co/LQMxpxT/DSCF0995.jpg",
    "https://i.ibb.co/TTnsjZn/PSL22181.jpg",
    // "https://i.ibb.co/9Vxm27n/PSL22177.jpg",
    "https://i.ibb.co/0Bx4LLX/DSCF1004.jpg",
    "https://i.ibb.co/SBW5K85/DSCF1008.jpg",
    "https://i.ibb.co/41TxnRd/IMG-5754.jpg",
    "https://i.ibb.co/fSJ0x5s/PSL22184.jpg",
    "https://i.ibb.co/DV70BwF/PSL22201.jpg",
    // "https://i.ibb.co/9qwjKcg/PSL22194.jpg",
    "https://i.ibb.co/v4xsxyt/TIM07986.jpg",
    "https://i.ibb.co/vvq4dJB/DSCF1011.jpg",
    "https://i.ibb.co/dP3Zyv2/DSCF1012.jpg",
    "https://i.ibb.co/PmdzTy3/DSCF1013.jpg",
    "https://i.ibb.co/3MwCnRs/DSCF1019.jpg",
    "https://i.ibb.co/vkJ4crv/DSCF1023.jpg",
    "https://i.ibb.co/vqYcnn0/DSCF1026.jpg",
    "https://i.ibb.co/tzP2XDs/DSCF1029.jpg",
    "https://i.ibb.co/XkMfXw2/DSCF1034.jpg",
    "https://i.ibb.co/dJvSyvL/DSCF1039.jpg",
    "https://i.ibb.co/yqr39td/TIM08004.jpg",
    "https://i.ibb.co/tK9wCvv/DSCF1043.jpg",
    "https://i.ibb.co/12XpvL4/TIM08013.jpg",
    "https://i.ibb.co/1JCM8jH/DSCF1046.jpg",
    "https://i.ibb.co/8gYtfDk/DSCF1055.jpg",
    "https://i.ibb.co/7tqvPL6/DSCF1059.jpg",
    "https://i.ibb.co/r64Mb6x/PSL22207.jpg",
    "https://i.ibb.co/JzpSGHF/TIM07999.jpg",
    "https://i.ibb.co/ZzLRJC8/DSCF1072.jpg",
    "https://i.ibb.co/b38rpDM/DSCF1073.jpg",
    "https://i.ibb.co/mJ2NKjk/DSCF1078.jpg",
    "https://i.ibb.co/bgYtj0b/TIM08002.jpg",
    "https://i.ibb.co/rcBdf79/IMG-5786.jpg",
    "https://i.ibb.co/7Qs1tng/IMG-5805.jpg",
    "https://i.ibb.co/SsYH4Cm/DSCF1088.jpg",
    "https://i.ibb.co/VBM9swC/DSCF1081.jpg",
    "https://i.ibb.co/FWc1yjD/DSCF1092.jpg",
    "https://i.ibb.co/JdZ84HS/DSCF1094.jpg",
    "https://i.ibb.co/g9YM79V/DSCF1096.jpg",
    "https://i.ibb.co/Wx1QfZW/IMG-5807.jpg",
    "https://i.ibb.co/nmc2qdg/IMG-5822.jpg",
    "https://i.ibb.co/VJj3c2W/IMG-5836.jpg",
    "https://i.ibb.co/1RVK8pJ/IMG-5845.jpg",
    "https://i.ibb.co/zxynmHc/DSCF1110.jpg",
    "https://i.ibb.co/hfM9ZpL/PSL22220.jpg",
    "https://i.ibb.co/JmgJ0BB/PSL22228.jpg",
    "https://i.ibb.co/hdT3hvT/IMG-5849.jpg",
    "https://i.ibb.co/SnYzDYc/PSL22241.jpg",
    "https://i.ibb.co/6YmmTxC/PSL22244.jpg",
    "https://i.ibb.co/HhC1X9Z/PSL22248.jpg",
    "https://i.ibb.co/B3jkLpm/PSL22253.jpg",
    "https://i.ibb.co/F3GSvZ4/PSL22250.jpg",
    "https://i.ibb.co/hYG7Yxc/PSL22264.jpg",
    "https://i.ibb.co/7K14JLb/IMG-5863.jpg",
    "https://i.ibb.co/BLJmFnf/IMG-5870.jpg",
    "https://i.ibb.co/hd3gsrt/IMG-5874.jpg",
    "https://i.ibb.co/QvxTvRP/IMG-5879.jpg",
    "https://i.ibb.co/dKxnbwF/IMG-5884.jpg",
    "https://i.ibb.co/LnHywNz/PSL22287.jpg",
    "https://i.ibb.co/djnTh1D/PSL22302.jpg",
    "https://i.ibb.co/k2QsgYR/PSL22306.jpg",
    "https://i.ibb.co/BZ2QGwD/PSL22308.jpg",
    "https://i.ibb.co/zs8dSz2/PSL22313.jpg",
    "https://i.ibb.co/T0Q0Wzr/IMG-5915.jpg",
    "https://i.ibb.co/qnj8Spv/IMG-5921.jpg",
    "https://i.ibb.co/BKpdDbT/IMG-5924.jpg",
    "https://i.ibb.co/pJjSRct/IMG-5940.jpg",
    "https://i.ibb.co/9sPnXPB/DSCF1132.jpg",
    "https://i.ibb.co/N7YD5WF/DSCF1136.jpg",
    "https://i.ibb.co/NLvHpRN/DSCF1137.jpg",
    "https://i.ibb.co/VjkVpbV/DSCF1140.jpg",
    "https://i.ibb.co/WxXJCPt/IMG-5949.jpg",
    "https://i.ibb.co/zrdhDXb/IMG-5955.jpg",
    "https://i.ibb.co/02mGw8n/DSCF1173.jpg",
    "https://i.ibb.co/LQQxNZ3/DSCF1154.jpg",
    "https://i.ibb.co/rbJghDc/DSCF1158.jpg",
    "https://i.ibb.co/hYVVTVx/DSCF1163.jpg",
    "https://i.ibb.co/BjSFvd1/IMG-5959.jpg",
    "https://i.ibb.co/ZXkk6zR/IMG-5964.jpg",
    "https://i.ibb.co/YfPh07j/DSCF1168.jpg",
    "https://i.ibb.co/89Q1FjV/DSCF1170.jpg",
    // "https://i.ibb.co/rp0bcY8/DSCF1179.jpg",
    "https://i.ibb.co/R9h7znK/PSL22317.jpg",
    "https://i.ibb.co/LYCkJN0/PSL22319.jpg",
    "https://i.ibb.co/jM7YqMj/IMG-5977.jpg",
    "https://i.ibb.co/kcBC8zY/IMG-5980.jpg",
    "https://i.ibb.co/1nykvqn/IMG-5984.jpg",
    "https://i.ibb.co/VvsCHW0/IMG-5986.jpg",
    "https://i.ibb.co/YLjrzP0/IMG-5989.jpg",
    "https://i.ibb.co/r042cCP/IMG-5992.jpg",
    "https://i.ibb.co/znXDnCM/DSCF1197.jpg",
    "https://i.ibb.co/Zc88GL1/IMG-5975.jpg",
    "https://i.ibb.co/dgpTtZv/IMG-5997.jpg",
    "https://i.ibb.co/1K9T7xL/IMG-5999.jpg",
    "https://i.ibb.co/kKMPrvp/DSCF1181.jpg",
    "https://i.ibb.co/SmDHzxZ/DSCF1190.jpg",
    "https://i.ibb.co/BNK79J5/DSCF1191.jpg",
    "https://i.ibb.co/BtfyzwT/IMG-6006.jpg",
    "https://i.ibb.co/Vxy7dZj/IMG-6003.jpg",
    "https://i.ibb.co/J77yWRg/IMG-6012.jpg",
    "https://i.ibb.co/z5nQLh4/IMG-6026.jpg",
    "https://i.ibb.co/Dp82PRN/DSCF1200.jpg",
    "https://i.ibb.co/LPG4Z6T/DSCF1204.jpg",
    "https://i.ibb.co/RP5pKLt/DSCF1224.jpg",
    "https://i.ibb.co/P11qQMh/PSL22337.jpg",
    "https://i.ibb.co/XDFXc1F/IMG-6028.jpg",
    "https://i.ibb.co/StR8SYS/IMG-6018.jpg",
    "https://i.ibb.co/VVxWdhW/IMG-6031.jpg",
    "https://i.ibb.co/bXFx3zh/IMG-6035.jpg",
    "https://i.ibb.co/KbnZv1g/IMG-6037.jpg",
    "https://i.ibb.co/mtVSGPV/IMG-6038.jpg",
    "https://i.ibb.co/yn0SBvg/IMG-6041.jpg",
    "https://i.ibb.co/610Cy8M/IMG-6042.jpg",
    "https://i.ibb.co/g3zkrxR/IMG-6045.jpg",
    "https://i.ibb.co/cLk1XWp/PSL22340.jpg",
    "https://i.ibb.co/DRNjqms/PSL22357.jpg",
    "https://i.ibb.co/6bK46Hj/PSL22360.jpg",
    "https://i.ibb.co/9VsR9f7/PSL22364.jpg",
    "https://i.ibb.co/Qnj1zzK/PSL22365.jpg",
    "https://i.ibb.co/vDvfJtf/PSL22374.jpg",
    "https://i.ibb.co/5x6JVXq/PSL22377.jpg",
    "https://i.ibb.co/bRcjm4J/PSL22378.jpg",
    "https://i.ibb.co/x2wc9D8/PSL22382.jpg",
    "https://i.ibb.co/pLQWzDt/IMG-6048.jpg",
    "https://i.ibb.co/Mprmggc/DSCF1243.jpg",
    "https://i.ibb.co/mqjSTZv/DSCF1250.jpg",
    "https://i.ibb.co/cy0XhxT/DSCF1255.jpg",
    "https://i.ibb.co/BCxy3w9/DSCF1261.jpg",
    "https://i.ibb.co/zRWk3Jn/DSCF1262.jpg",
    "https://i.ibb.co/QYd3Dcc/DSCF1267.jpg",
    "https://i.ibb.co/hf1kVdj/DSCF1276.jpg",
    "https://i.ibb.co/x6DQBtX/DSCF1281.jpg",
    "https://i.ibb.co/rZSjSMb/DSCF1290.jpg",
    "https://i.ibb.co/P6sqKMK/DSCF1294.jpg",
    "https://i.ibb.co/tPw8Pg9/DSCF1297.jpg",
    "https://i.ibb.co/XzCmJ1B/DSCF1301.jpg",
    "https://i.ibb.co/L8zpjxW/DSCF1313.jpg",
    "https://i.ibb.co/W0QWkhR/DSCF1320.jpg",
    "https://i.ibb.co/MRv0JHy/IMG-6052.jpg",
  ],
  thumbnails: [
    "https://i.ibb.co/SNkq7p7/PSL22152.jpg",
    "https://i.ibb.co/vhCh1qy/IMG-5704.jpg",
    "https://i.ibb.co/T2qGc7v/PSL22158.jpg",
    "https://i.ibb.co/XZ7VtNz/PSL22166.jpg",
    "https://i.ibb.co/WHn7Xn1/DSCF0958.jpg",
    "https://i.ibb.co/GM5nR2H/DSCF0963.jpg",
    "https://i.ibb.co/pn3cnCX/DSCF0968.jpg",
    "https://i.ibb.co/xMVct6R/IMG-5726.jpg",
    "https://i.ibb.co/HdwGkqq/DSCF0977.jpg",
    "https://i.ibb.co/tbHKjKs/TIM07987.jpg",
    "https://i.ibb.co/N3cZjsG/IMG-5729.jpg",
    "https://i.ibb.co/vd5VXF1/DSCF0980.jpg",
    "https://i.ibb.co/P4qBY5d/DSCF0983.jpg",
    "https://i.ibb.co/Mkc0qZp/IMG-5737.jpg",
    "https://i.ibb.co/LCzjqhT/DSCF0991.jpg",
    "https://i.ibb.co/28wvKvz/DSCF0995.jpg",
    "https://i.ibb.co/QCW7n49/PSL22177.jpg",
    "https://i.ibb.co/Qcq4SZq/PSL22181.jpg",
    "https://i.ibb.co/CJfCyyt/DSCF1004.jpg",
    "https://i.ibb.co/0r8mfSm/DSCF1008.jpg",
    "https://i.ibb.co/hYWvJFf/IMG-5754.jpg",
    "https://i.ibb.co/LPKpZW3/PSL22184.jpg",
    "https://i.ibb.co/HrNQwB4/PSL22194.jpg",
    "https://i.ibb.co/h7K3G2N/PSL22201.jpg",
    "https://i.ibb.co/7tJgJM9/TIM07986.jpg",
    "https://i.ibb.co/84sgxjB/DSCF1011.jpg",
    "https://i.ibb.co/0CW53LM/DSCF1012.jpg",
    "https://i.ibb.co/9873w0z/DSCF1013.jpg",
    "https://i.ibb.co/CMqHfPt/DSCF1019.jpg",
    "https://i.ibb.co/VTvYLhp/DSCF1023.jpg",
    "https://i.ibb.co/F3VhTTR/DSCF1026.jpg",
    "https://i.ibb.co/QkjHdMN/DSCF1029.jpg",
    "https://i.ibb.co/h1wxsq7/DSCF1034.jpg",
    "https://i.ibb.co/JFTNPTy/DSCF1039.jpg",
    "https://i.ibb.co/PgdSLKF/TIM08004.jpg",
    "https://i.ibb.co/fFsV0yy/DSCF1043.jpg",
    "https://i.ibb.co/4NSDm70/TIM08013.jpg",
    "https://i.ibb.co/yBzpW7L/DSCF1046.jpg",
    "https://i.ibb.co/DfrTvDd/DSCF1055.jpg",
    "https://i.ibb.co/RTq2fdV/DSCF1059.jpg",
    "https://i.ibb.co/pzJZWzf/PSL22207.jpg",
    "https://i.ibb.co/7Gp0ZKN/TIM07999.jpg",
    "https://i.ibb.co/jk695FM/DSCF1072.jpg",
    "https://i.ibb.co/FWjBGCL/DSCF1073.jpg",
    "https://i.ibb.co/2qCZr9x/DSCF1078.jpg",
    "https://i.ibb.co/7JBhxfk/TIM08002.jpg",
    "https://i.ibb.co/8dyY4Kk/IMG-5786.jpg",
    "https://i.ibb.co/2SGk3v8/IMG-5805.jpg",
    "https://i.ibb.co/dWCXwYp/DSCF1088.jpg",
    "https://i.ibb.co/x5zLNFg/DSCF1081.jpg",
    "https://i.ibb.co/wL8qPxz/DSCF1092.jpg",
    "https://i.ibb.co/j5tq2Dj/DSCF1094.jpg",
    "https://i.ibb.co/8skDBsc/DSCF1096.jpg",
    "https://i.ibb.co/xCQy6WS/IMG-5807.jpg",
    "https://i.ibb.co/bmBhjwW/IMG-5822.jpg",
    "https://i.ibb.co/6rnN7gR/IMG-5836.jpg",
    "https://i.ibb.co/3dDrTYX/DSCF1110.jpg",
    "https://i.ibb.co/JrL25bF/IMG-5845.jpg",
    "https://i.ibb.co/m8BTXQb/PSL22220.jpg",
    "https://i.ibb.co/sbzYkmm/PSL22228.jpg",
    "https://i.ibb.co/z2tqjwt/IMG-5849.jpg",
    "https://i.ibb.co/mqZ3rZ0/PSL22241.jpg",
    "https://i.ibb.co/0qcc6Sb/PSL22244.jpg",
    "https://i.ibb.co/SfcWJZS/PSL22248.jpg",
    "https://i.ibb.co/dM6ZBTS/PSL22253.jpg",
    "https://i.ibb.co/qjbtGqY/PSL22250.jpg",
    "https://i.ibb.co/DrBVrPY/PSL22264.jpg",
    "https://i.ibb.co/hVmfRwC/IMG-5863.jpg",
    "https://i.ibb.co/31bwQRT/IMG-5870.jpg",
    "https://i.ibb.co/fNjXMZP/IMG-5874.jpg",
    "https://i.ibb.co/v4MT4G3/IMG-5879.jpg",
    "https://i.ibb.co/Qmts83L/IMG-5884.jpg",
    "https://i.ibb.co/nzty2Ps/PSL22287.jpg",
    "https://i.ibb.co/mbQjx14/PSL22302.jpg",
    "https://i.ibb.co/B2q5V8X/PSL22306.jpg",
    "https://i.ibb.co/PhGPMtp/PSL22308.jpg",
    "https://i.ibb.co/Q98064H/PSL22313.jpg",
    "https://i.ibb.co/3FxFRtk/IMG-5915.jpg",
    "https://i.ibb.co/RSbKFcq/IMG-5921.jpg",
    "https://i.ibb.co/zHMjcpP/IMG-5924.jpg",
    "https://i.ibb.co/Fg4F02S/IMG-5940.jpg",
    "https://i.ibb.co/r49kP9q/DSCF1132.jpg",
    "https://i.ibb.co/kMcbjqG/DSCF1136.jpg",
    "https://i.ibb.co/GV6bkmM/DSCF1137.jpg",
    "https://i.ibb.co/mDPJFdJ/DSCF1140.jpg",
    "https://i.ibb.co/TvG3ybL/IMG-5949.jpg",
    "https://i.ibb.co/KXnjS27/IMG-5955.jpg",
    "https://i.ibb.co/vqVjtgc/DSCF1173.jpg",
    "https://i.ibb.co/WDDkV2L/DSCF1154.jpg",
    "https://i.ibb.co/jVFdYnW/DSCF1158.jpg",
    "https://i.ibb.co/NTKKGKg/DSCF1163.jpg",
    "https://i.ibb.co/mtMgd1x/IMG-5959.jpg",
    "https://i.ibb.co/1QwwfGY/IMG-5964.jpg",
    "https://i.ibb.co/3S4cRyh/DSCF1168.jpg",
    "https://i.ibb.co/pdkYC3p/DSCF1170.jpg",
    "https://i.ibb.co/Wz5DPZ8/DSCF1179.jpg",
    "https://i.ibb.co/Qf8XrBV/PSL22317.jpg",
    "https://i.ibb.co/8NjKdYm/PSL22319.jpg",
    "https://i.ibb.co/d2nvX2H/IMG-5977.jpg",
    "https://i.ibb.co/QmQwNhs/IMG-5980.jpg",
    "https://i.ibb.co/TTjsv8T/IMG-5984.jpg",
    "https://i.ibb.co/RCq6hpG/IMG-5986.jpg",
    "https://i.ibb.co/878hWNY/IMG-5989.jpg",
    "https://i.ibb.co/SyRwdhT/IMG-5992.jpg",
    "https://i.ibb.co/2nvHnR9/DSCF1197.jpg",
    "https://i.ibb.co/1Rqq7vJ/IMG-5975.jpg",
    "https://i.ibb.co/gVW2dY8/IMG-5997.jpg",
    "https://i.ibb.co/Y8thPG3/IMG-5999.jpg",
    "https://i.ibb.co/8MXQRtJ/DSCF1181.jpg",
    "https://i.ibb.co/FYkcFWd/DSCF1190.jpg",
    "https://i.ibb.co/FVqfF1L/DSCF1191.jpg",
    "https://i.ibb.co/f2Xk1Qr/IMG-6006.jpg",
    "https://i.ibb.co/T1XVZSv/IMG-6003.jpg",
    "https://i.ibb.co/pzzR6v5/IMG-6012.jpg",
    "https://i.ibb.co/ZJLGw6M/IMG-6026.jpg",
    "https://i.ibb.co/hfZzxmk/DSCF1200.jpg",
    "https://i.ibb.co/z5kK4b3/DSCF1204.jpg",
    "https://i.ibb.co/2Pb7rXR/DSCF1224.jpg",
    "https://i.ibb.co/GCCzT92/PSL22337.jpg",
    "https://i.ibb.co/VxMVfRM/IMG-6028.jpg",
    "https://i.ibb.co/v3ZG9y9/IMG-6018.jpg",
    "https://i.ibb.co/Q9kFZqF/IMG-6031.jpg",
    "https://i.ibb.co/YhX6Wp9/IMG-6035.jpg",
    "https://i.ibb.co/bQth9fV/IMG-6037.jpg",
    "https://i.ibb.co/5vg26Jg/IMG-6038.jpg",
    "https://i.ibb.co/N6xSnDW/IMG-6041.jpg",
    "https://i.ibb.co/D8f21YJ/IMG-6042.jpg",
    "https://i.ibb.co/bPzc6kK/IMG-6045.jpg",
    "https://i.ibb.co/WtG6prd/PSL22340.jpg",
    "https://i.ibb.co/0Gkzw18/PSL22357.jpg",
    "https://i.ibb.co/FJQBG5R/PSL22360.jpg",
    "https://i.ibb.co/5nBqWzP/PSL22364.jpg",
    "https://i.ibb.co/y5BbGGQ/PSL22365.jpg",
    "https://i.ibb.co/fQGZCcZ/PSL22374.jpg",
    "https://i.ibb.co/fG2T7Vm/PSL22377.jpg",
    "https://i.ibb.co/mFfrTn8/PSL22378.jpg",
    "https://i.ibb.co/9pBfKN3/PSL22382.jpg",
    "https://i.ibb.co/VqxHSy7/IMG-6048.jpg",
    "https://i.ibb.co/xGWN221/DSCF1243.jpg",
    "https://i.ibb.co/QNgXQS8/DSCF1250.jpg",
    "https://i.ibb.co/JcVFjCk/DSCF1255.jpg",
    "https://i.ibb.co/DRy9WLZ/DSCF1261.jpg",
    "https://i.ibb.co/qsLZH5y/DSCF1262.jpg",
    "https://i.ibb.co/P1QvDYY/DSCF1267.jpg",
    "https://i.ibb.co/tXPSD2t/DSCF1276.jpg",
    "https://i.ibb.co/zZrwBTQ/DSCF1281.jpg",
    "https://i.ibb.co/D7yJykG/DSCF1290.jpg",
    "https://i.ibb.co/YL4HmXm/DSCF1294.jpg",
    "https://i.ibb.co/qFLkFKv/DSCF1297.jpg",
    "https://i.ibb.co/LCZ4pmw/DSCF1301.jpg",
    "https://i.ibb.co/kxgcb4f/DSCF1313.jpg",
    "https://i.ibb.co/MfbDcvJ/DSCF1320.jpg",
    "https://i.ibb.co/7Gfm0Dx/IMG-6052.jpg",
  ],
};

export default album;
