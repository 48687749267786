const album = {
  title: "album5",
  images: [
    "https://i.ibb.co/Q7wwTYt/G1-A0323-min.jpg",
    "https://i.ibb.co/Gnh2kZL/TDP-8486-min.jpg",
    "https://i.ibb.co/KbhQdK7/TDP-8456-min.jpg",
    "https://i.ibb.co/1LB2CrP/D6-A0390-min.jpg",
    "https://i.ibb.co/Vttf1Y0/DSC09513-min.jpg",
    "https://i.ibb.co/KrhQrJr/DSC09268-min.jpg",
    "https://i.ibb.co/qmS7JVW/DSC09258-min.jpg",
    "https://i.ibb.co/8My1f3k/DSC09246-min.jpg",
    "https://i.ibb.co/X2VRzLd/DSC09182-min.jpg",
    "https://i.ibb.co/GJwz5SV/G1-A0238-min.jpg",
    "https://i.ibb.co/ySQFhj2/G1-A0204-min.jpg",
    "https://i.ibb.co/r47sNDT/G1-A0174-min.jpg",
    "https://i.ibb.co/1X2gYZW/G1-A0103-min.jpg",
    "https://i.ibb.co/NVLCdNF/G1-A0085-min.jpg",
    "https://i.ibb.co/bBF1ktX/G1-A0062-min.jpg",
    "https://i.ibb.co/SxHGwKb/G1-A0038-min.jpg",
    "https://i.ibb.co/qYPSmss/D6-A9957-min.jpg",
    "https://i.ibb.co/T02LpHH/D6-A9898-min.jpg",
    "https://i.ibb.co/h96187H/D6-A9833-min.jpg",
    "https://i.ibb.co/TtfSfQ9/D6-A9802-min.jpg",
    "https://i.ibb.co/DzpHYYj/D6-A9772-min.jpg",
    "https://i.ibb.co/ZGWL8XM/D6-A9763-min.jpg",
    "https://i.ibb.co/RyHKYgB/D6-A1553-min.jpg",
    "https://i.ibb.co/CHnz68R/D6-A1517-min.jpg",
    "https://i.ibb.co/CJzk4zw/D6-A1437-min.jpg",
    "https://i.ibb.co/HDxH166/D6-A1402-min.jpg",
    "https://i.ibb.co/PFzRPqH/D6-A0667-min.jpg",
    "https://i.ibb.co/bzngMjZ/D6A0642.jpg",
    "https://i.ibb.co/X8L3RdB/D6-A0569-min.jpg",
    "https://i.ibb.co/sWqMGvB/D6-A0561-min.jpg",
    "https://i.ibb.co/wwNgxXw/D6-A0546-min.jpg",
    "https://i.ibb.co/THTZHSf/D6-A0472-min.jpg",
    "https://i.ibb.co/8cYXTYK/D6-A0224-min.jpg",
    "https://i.ibb.co/PmLtDtD/D6-A0149-min.jpg",
    "https://i.ibb.co/r3dBKh0/D6-A0125-min.jpg",
    "https://i.ibb.co/pKbgCqk/TDP-8678-min.jpg",
  ],
  thumbnails: [
    "https://i.ibb.co/NVv7T7T/D6-A0149-min.jpg",
    "https://i.ibb.co/yp4zmCP/D6-A0125-min.jpg",
    "https://i.ibb.co/JQ3C03B/D6-A0224-min.jpg",
    "https://i.ibb.co/jTq4CyQ/D6-A0390-min.jpg",
    "https://i.ibb.co/sFPhFGf/D6-A0472-min.jpg",
    "https://i.ibb.co/gPM9BpP/D6-A0546-min.jpg",
    "https://i.ibb.co/SfB98XL/D6-A0561-min.jpg",
    "https://i.ibb.co/vYqQnr5/D6-A0569-min.jpg",
    "https://i.ibb.co/ydBDZHj/D6-A0667-min.jpg",
    "https://i.ibb.co/rGcwTYY/D6-A1402-min.jpg",
    "https://i.ibb.co/1sLDBL0/D6-A1437-min.jpg",
    "https://i.ibb.co/W2c605m/D6-A1517-min.jpg",
    "https://i.ibb.co/GTPDndt/D6-A1553-min.jpg",
    "https://i.ibb.co/fq41pFx/D6-A9763-min.jpg",
    "https://i.ibb.co/BcN5GGY/D6-A9772-min.jpg",
    "https://i.ibb.co/w4kXkn5/D6-A9802-min.jpg",
    "https://i.ibb.co/3d3YhrW/D6-A9833-min.jpg",
    "https://i.ibb.co/KshwR99/D6-A9898-min.jpg",
    "https://i.ibb.co/F4jr8ww/D6-A9957-min.jpg",
    "https://i.ibb.co/LPBHdCm/G1-A0038-min.jpg",
    "https://i.ibb.co/BZGL9kB/G1-A0062-min.jpg",
    "https://i.ibb.co/JBztwpc/G1-A0085-min.jpg",
    "https://i.ibb.co/wQ6kZym/G1-A0103-min.jpg",
    "https://i.ibb.co/pJ0y5YG/G1-A0174-min.jpg",
    "https://i.ibb.co/gVRZvKY/G1-A0204-min.jpg",
    "https://i.ibb.co/SxTF3CK/G1-A0238-min.jpg",
    "https://i.ibb.co/0pbb6Yv/G1-A0323-min.jpg",
    "https://i.ibb.co/yfVj8PM/DSC09182-min.jpg",
    "https://i.ibb.co/D4B0vXd/DSC09246-min.jpg",
    "https://i.ibb.co/ZcjG27Y/DSC09258-min.jpg",
    "https://i.ibb.co/qrj4rvr/DSC09268-min.jpg",
    "https://i.ibb.co/9NNQDHm/DSC09513-min.jpg",
    "https://i.ibb.co/hL9nbYH/TDP-8456-min.jpg",
    "https://i.ibb.co/Kb1q7tk/TDP-8486-min.jpg",
    "https://i.ibb.co/rpFgn9X/TDP-8678-min.jpg",
    "https://i.ibb.co/vZ8x6CG/D6A0642.jpg",
  ],
};

export default album;
