import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import PreWed1 from './PreWedding/1_The_Youth';
import PreWed2 from './PreWedding/2_Stand_By_Me';
import PreWed3 from './PreWedding/3_Beautiful_In_White';
import PreWed4 from './PreWedding/4_Autumn_For_You';
import PreWed5 from './PreWedding/5_Cant_Help';

import Album1 from './WeddingCeremony/1_Getting_Ready';
import Album2 from './WeddingCeremony/2_Decoration';
import Album3 from './WeddingCeremony/3_Traditional_Dress';
import Album4 from './WeddingCeremony/4_Traditional_Ceremony';
import Album5 from './WeddingCeremony/5_Bride_and_Groom';
import Album6 from './WeddingCeremony/6_Guest_Welcoming';
import Album7 from './WeddingCeremony/7_The_Vow';
import Album8 from './WeddingCeremony/8_Dinner_and_Fun_time';

import USA1 from './WeddingUSA/Album1';
import USA2 from './WeddingUSA/Album2';
import USA3 from './WeddingUSA/Album3';
import USA4 from './WeddingUSA/Album4';

import './FullPageGallery.scss';
import 'yet-another-react-lightbox/styles.css';

const DownloadButton = ({ image }) => {
  const handleClick = () => {
    const fileName = image.substring(image.lastIndexOf('/') + 1);
    saveAs(image, fileName);
  };

  return (
    <button
      type='button download-btn'
      aria-label='Download'
      className='yarl__button'
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faDownload} style={{ height: 19 }} />
    </button>
  );
};

function FullPageGallery() {
  const { t } = useTranslation();
  const location = useLocation();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showLightBox, setShowLightBox] = useState(false);

  let allAlbums;

  if (location.pathname.includes('prewedding')) {
    allAlbums = [PreWed1, PreWed2, PreWed3, PreWed4, PreWed5];
  } else if (location.pathname.includes('wedding')) {
    allAlbums = [
      Album1,
      Album2,
      Album3,
      Album4,
      Album5,
      Album6,
      Album7,
      Album8,
    ];
  } else {
    allAlbums = [USA1, USA2, USA3, USA4];
  }

  const mapImageAndThumbnail = (album) => {
    return album.images.map((image) => {
      const imageName = image.substring(image.lastIndexOf('/') + 1);
      const thumbnail =
        album.thumbnails.find((thumbnail) => thumbnail.includes(imageName)) ||
        image;

      return { src: image, thumbnail };
    });
  };

  const mapImageAndThumbnailUSA = (album) => {
    return album.order.map((name) => {
      const imageName = `/images/usa/compressed/${name}`;
      console.log('imageName :', imageName);

      const thumbnail = `/images/usa/thumbnail/${name}`;
      console.log('thumbnail :', thumbnail);

      return { src: imageName, thumbnail };
    });
  };

  const toggleLightBox = (image) => {
    const index = allImages.findIndex((ele) => ele.src === image.src);
    setCurrentIndex(index);
    setShowLightBox(!showLightBox);
  };

  const getAlbumTitle = (title) => {
    if (location.pathname.includes('prewedding')) {
      return t('gallery.prewedding.' + title);
    } else if (location.pathname.includes('wedding')) {
      return t('gallery.weddingDay.' + title);
    } else {
      return '';
    }
  };

  const getHeadingTitle = () => {
    if (location.pathname.includes('prewedding')) {
      return t('gallery.headingPreWedding');
    } else if (location.pathname.includes('wedding')) {
      return t('gallery.headingWedding');
    } else {
      return t('gallery.headingUSA');
    }
  };

  const getHeadingClass = () => {
    if (location.pathname.includes('prewedding')) {
      return 'prewedding';
    } else if (location.pathname.includes('wedding')) {
      return '';
    } else {
      return 'usa';
    }
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);

  let allImages;

  if (location.pathname.includes('usa')) {
    allImages = allAlbums.map((album) => mapImageAndThumbnailUSA(album)).flat();
    console.log('allImages :', allImages);
  } else {
    allImages = allAlbums.map((album) => mapImageAndThumbnail(album)).flat();
  }

  return (
    <div className='full-page-gallery' id='home'>
      <div className={`heading ${getHeadingClass()}`}>
        <div className='heading-overlay'></div>
        <div className='heading-title'>{getHeadingTitle()}</div>
      </div>

      {allAlbums.map((album) => {
        return (
          <section className='album-section' key={album.title}>
            <h1 id={album.title}>{getAlbumTitle(album.title)}</h1>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4 }}
            >
              <Masonry className='masonry' columnsCount={4} gutter='10px'>
                {location.pathname.includes('usa')
                  ? mapImageAndThumbnailUSA(album).map((image, index) => (
                      <LazyLoadImage
                        key={index}
                        src={image.thumbnail}
                        width={'auto'}
                        height={'auto'}
                        onClick={() => toggleLightBox(image)}
                      />
                    ))
                  : mapImageAndThumbnail(album).map((image, index) => (
                      <LazyLoadImage
                        key={index}
                        src={image.thumbnail}
                        width={'auto'}
                        height={'auto'}
                        onClick={() => toggleLightBox(image)}
                      />
                    ))}
              </Masonry>
            </ResponsiveMasonry>
          </section>
        );
      })}

      <Lightbox
        open={showLightBox}
        close={() => setShowLightBox(false)}
        index={currentIndex}
        slides={allImages}
        plugins={[Zoom]}
        toolbar={{
          buttons: [
            <DownloadButton
              key='download'
              image={allImages[currentIndex].src}
            />,
            'close',
          ],
        }}
        on={{
          view: (index) => {
            setCurrentIndex(index);
          },
        }}
      />
    </div>
  );
}

export default FullPageGallery;
