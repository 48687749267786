import React from "react";
import LanguageButton from "../LanguageButton/LanguageButton";

import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="logo">Đạt &nbsp;&amp; Quỳnh</div>
      <div className="copyright">
        © 2022 Made with<span> ❤️ </span>by
        <strong> Dat Truong</strong>. Original design by
        <strong> Catanis</strong>.
      </div>
      <LanguageButton />
    </footer>
  );
};

export default Footer;
