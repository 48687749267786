import React from "react";
import Footer from "../../components/Footer/Footer";
import FullPageGallery from "../../components/FullPageGallery/FullPageGallery";
import Menu from "../../components/Menu/Menu";

const Gallery = () => {
  return (
    <>
      <Menu type="secondary" />
      <FullPageGallery />;
      <Footer />
    </>
  );
};

export default Gallery;
