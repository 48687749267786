import React from "react";

import "./StoryTimeline.scss";

import album from "../../images/icon/album.png";
import balloon from "../../images/icon/balloon.png";
import rings from "../../images/icon/rings.png";
import couple from "../../images/icon/couple.png";

import milestone1 from "../../images/milestone/milestone1.jpg";
import milestone2 from "../../images/milestone/milestone2.jpg";
import milestone3 from "../../images/milestone/milestone3.jpg";
import milestone4 from "../../images/milestone/milestone4a.jpeg";
import { useTranslation } from "react-i18next";

function StoryTimeline() {
  const { t } = useTranslation();

  return (
    <section className="story-timeline">
      <div className="timeline-heading">{t("timeline.title")}</div>

      <div className="timeline">
        <div className="milestone">
          <div className="line top">
            <div className="icon-wrapper">
              <img className="icon small" src={album} alt="" />
            </div>
          </div>
          <img className="image" src={milestone1} alt="" />
          <div className="content">
            <div className="title">{t("timeline.milestone1.title")}</div>
            <div className="date">{t("timeline.milestone1.date")}</div>
            <p className="desc">{t("timeline.milestone1.desc")}</p>
          </div>
        </div>
        <div className="milestone">
          <div className="line">
            <div className="icon-wrapper">
              <img className="icon" src={balloon} alt="" />
            </div>
          </div>
          <img className="image reverse" src={milestone2} alt="" />
          <div className="content reverse">
            <div className="title">{t("timeline.milestone2.title")}</div>
            <div className="date">{t("timeline.milestone2.date")}</div>
            <p className="desc">{t("timeline.milestone2.desc")}</p>
          </div>
        </div>
        <div className="milestone">
          <div className="line">
            <div className="icon-wrapper">
              <img className="icon small" src={rings} alt="" />
            </div>
          </div>
          <img className="image" src={milestone3} alt="" />
          <div className="content">
            <div className="title">{t("timeline.milestone3.title")}</div>
            <div className="date">{t("timeline.milestone3.date")}</div>
            <p className="desc">
              {t("timeline.milestone3.desc")} :"{">"}
            </p>
          </div>
        </div>
        <div className="milestone">
          <div className="line bottom">
            <div className="icon-wrapper">
              <img className="icon large" src={couple} alt="" />
            </div>
          </div>
          <img className="image reverse" src={milestone4} alt="" />
          <div className="content reverse">
            <div className="title">{t("timeline.milestone4.title")}</div>
            <div className="date">{t("timeline.milestone4.date")}</div>
            <p className="desc">
              {t("timeline.milestone4.desc")}
              <br /> &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
              &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
              &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StoryTimeline;
