const album = {
  title: "album2",
  images: [
    "https://i.ibb.co/jTYtJJ8/AMV-1242-min.jpg",
    "https://i.ibb.co/fN11JqR/AMV-1305-min.jpg",
    "https://i.ibb.co/RTkXFQM/AMV-1385-min.jpg",
    "https://i.ibb.co/hXVnRyW/AMV-1447-min.jpg",
    "https://i.ibb.co/nwTS90R/AMV-1583-min.jpg",
    "https://i.ibb.co/rfX682b/AMV-1594-min.jpg",
    "https://i.ibb.co/q5J8gyS/AMV-1712-min.jpg",
    "https://i.ibb.co/N2rvpKq/AMV-1756-min.jpg",
    "https://i.ibb.co/HxwKxpX/AMV-1770-min.jpg",
    "https://i.ibb.co/VgZwkg1/AMV-1820-min.jpg",
    "https://i.ibb.co/6488k7y/AMV-1908-min.jpg",
    "https://i.ibb.co/Gxz7Sw8/AMV-1945-min.jpg",
    "https://i.ibb.co/QQ41scg/AMV-1972-min.jpg",
    "https://i.ibb.co/P11gpY9/AMV-1985-min.jpg",
    "https://i.ibb.co/KyZKBjQ/AMV-2018-min.jpg",
    "https://i.ibb.co/BV2TcqV/AMV-2054-min.jpg",
    "https://i.ibb.co/x3KCCdx/AMV-2128-min.jpg",
    "https://i.ibb.co/ftYTjdW/AMV-2139-min.jpg",
    "https://i.ibb.co/WPgzdbT/AMV-2188-min.jpg",
  ],
  thumbnails: [
    "https://i.ibb.co/PNykDDr/AMV-1242-min.jpg",
    "https://i.ibb.co/25XkJPv/AMV-1186-min.jpg",
    "https://i.ibb.co/2MnnJW2/AMV-1305-min.jpg",
    "https://i.ibb.co/4Rnbq4D/AMV-1385-min.jpg",
    "https://i.ibb.co/SQB1XPn/AMV-1447-min.jpg",
    "https://i.ibb.co/Rg1WfS7/AMV-1583-min.jpg",
    "https://i.ibb.co/vvMPy4s/AMV-1594-min.jpg",
    "https://i.ibb.co/DprFMVJ/AMV-1712-min.jpg",
    "https://i.ibb.co/pjQ7ZdT/AMV-1756-min.jpg",
    "https://i.ibb.co/cCfyCXx/AMV-1770-min.jpg",
    "https://i.ibb.co/2vQZpv9/AMV-1820-min.jpg",
    "https://i.ibb.co/0fKKxRn/AMV-1908-min.jpg",
    "https://i.ibb.co/h73sz54/AMV-1945-min.jpg",
    "https://i.ibb.co/fFsPmSg/AMV-1972-min.jpg",
    "https://i.ibb.co/GCCRwJt/AMV-1985-min.jpg",
    "https://i.ibb.co/VBywbWh/AMV-2018-min.jpg",
    "https://i.ibb.co/KjxVbFj/AMV-2054-min.jpg",
    "https://i.ibb.co/N7gVVbc/AMV-2128-min.jpg",
    "https://i.ibb.co/ctcs4h9/AMV-2139-min.jpg",
    "https://i.ibb.co/mX6yYLx/AMV-2188-min.jpg",
  ],
};

export default album;
