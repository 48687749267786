const album = {
  title: "gettingReady",
  images: [
    "https://i.ibb.co/djhsmpV/DSCF0004.jpg",
    "https://i.ibb.co/cgYZ27b/DSCF0006.jpg",
    "https://i.ibb.co/vm15gWY/DSCF0008.jpg",
    "https://i.ibb.co/rMZ7hHb/IMG-4608.jpg",
    "https://i.ibb.co/g6WKwtj/TIM07869.jpg",

    "https://i.ibb.co/rtmm8Yr/IMG-4621.jpg",
    "https://i.ibb.co/Gdjh6rx/IMG-4626.jpg",
    "https://i.ibb.co/kcy0PD4/IMG-4671.jpg",
    "https://i.ibb.co/jvwHpqx/IMG-4673.jpg",

    "https://i.ibb.co/sK23KP8/PSL21139.jpg",
    "https://i.ibb.co/cFmScXy/PSL21143.jpg",
    "https://i.ibb.co/pxwHHJs/DSCF0017.jpg",
    "https://i.ibb.co/28LjgyT/DSCF0022.jpg",

    "https://i.ibb.co/Q9wfw7b/PSL21146.jpg",
    "https://i.ibb.co/Sw848zD/PSL21149.jpg",
    "https://i.ibb.co/ctsJ6vP/PSL21151.jpg",
    "https://i.ibb.co/RPKKTzT/PSL21152.jpg",
    "https://i.ibb.co/ckdhLZD/PSL21156.jpg",
    "https://i.ibb.co/7Y2xPY1/PSL21158.jpg",
    "https://i.ibb.co/3dbb0WC/PSL21159.jpg",
    "https://i.ibb.co/XJY0CWG/PSL21178.jpg",
    "https://i.ibb.co/DR9wgp5/TIM07879.jpg",

    "https://i.ibb.co/56RzMnf/IMG-4642.jpg",
    "https://i.ibb.co/bd0GtH2/IMG-4638.jpg",

    "https://i.ibb.co/5cnXggt/IMG-4675.jpg",
    "https://i.ibb.co/gFQPQqy/IMG-4681.jpg",
    "https://i.ibb.co/w0hhkmK/IMG-4684.jpg",
    "https://i.ibb.co/gyhjcX3/IMG-4694.jpg",
    "https://i.ibb.co/j340nVC/IMG-4701.jpg",
    "https://i.ibb.co/wsSstJs/IMG-4719.jpg",

    "https://i.ibb.co/q5Q5TKg/TIM07889.jpg",
  ],

  thumbnails: [
    "https://i.ibb.co/n6WqP1Y/DSCF0004.jpg",
    "https://i.ibb.co/xCFBzRm/DSCF0006.jpg",
    "https://i.ibb.co/ctJ5M9L/DSCF0008.jpg",
    "https://i.ibb.co/7XC332T/DSCF0017.jpg",
    "https://i.ibb.co/bHyrzPk/DSCF0022.jpg",
    "https://i.ibb.co/d6QWvDb/IMG-4608.jpg",
    "https://i.ibb.co/X4bb01R/IMG-4621.jpg",
    "https://i.ibb.co/XWTwQG2/IMG-4626.jpg",
    "https://i.ibb.co/mzjPpvX/IMG-4638.jpg",
    "https://i.ibb.co/9Nrf8VC/IMG-4642.jpg",
    "https://i.ibb.co/h8MHTdB/IMG-4671.jpg",
    "https://i.ibb.co/Kjsm1BQ/IMG-4673.jpg",
    "https://i.ibb.co/kc9Vssk/IMG-4675.jpg",
    "https://i.ibb.co/HpRxRw7/IMG-4681.jpg",
    "https://i.ibb.co/SswwjCK/IMG-4684.jpg",
    "https://i.ibb.co/F6CxdfK/IMG-4694.jpg",
    "https://i.ibb.co/1R2yFsC/IMG-4701.jpg",
    "https://i.ibb.co/k141Z31/IMG-4719.jpg",
    "https://i.ibb.co/3p7hpsg/PSL21139.jpg",
    "https://i.ibb.co/T8CxBqw/PSL21143.jpg",
    "https://i.ibb.co/PxHNHsm/PSL21146.jpg",
    "https://i.ibb.co/tptTt9w/PSL21149.jpg",
    "https://i.ibb.co/k0PK4yp/PSL21151.jpg",
    "https://i.ibb.co/2Prr3g3/PSL21152.jpg",
    "https://i.ibb.co/q7wY5ck/PSL21156.jpg",
    "https://i.ibb.co/WxgNZx2/PSL21158.jpg",
    "https://i.ibb.co/ZXrrhKM/PSL21159.jpg",
    "https://i.ibb.co/vzXyjcM/PSL21178.jpg",
    "https://i.ibb.co/25M4Pcn/TIM07869.jpg",
    "https://i.ibb.co/jry3ZRg/TIM07879.jpg",
    "https://i.ibb.co/jRXRnmw/TIM07889.jpg",
  ],
};

export default album;
