const album = {
  title: "album4",
  images: [
    "https://i.ibb.co/zGmw5hF/D6-A2388-min.jpg",
    "https://i.ibb.co/RT2zFq5/TDP-8761-min.jpg",
    "https://i.ibb.co/6n8wFw7/D6-A2726-min.jpg",
    "https://i.ibb.co/PDjqDPw/D6-A2302-min.jpg",
    "https://i.ibb.co/S7gzqW0/D6-A1782-min.jpg",
    "https://i.ibb.co/D1wSLpF/TDP-8742-min.jpg",
    "https://i.ibb.co/CmSgLZ7/TDP-8751-min.jpg",
    "https://i.ibb.co/rQxF8tR/TDP-8784-min.jpg",
    "https://i.ibb.co/2N80WtP/D6-A2713-min.jpg",
    "https://i.ibb.co/LYBNgpV/D6-A2591-min.jpg",
    "https://i.ibb.co/HDcBMQt/D6-A2463-min.jpg",
    "https://i.ibb.co/vBKLNjD/D6-A2244-min.jpg",
    "https://i.ibb.co/r6mQh7y/D6-A2154-min.jpg",
    "https://i.ibb.co/vXkNGNm/D6-A2088-min.jpg",
    "https://i.ibb.co/g3yncdw/TDP-8932-min.jpg",
    "https://i.ibb.co/gwXv2mR/TDP-8887-min.jpg",
    "https://i.ibb.co/NtjBBL0/TDP-8880-min.jpg",
    "https://i.ibb.co/YDQBnW4/D6-A2845-min.jpg",
    "https://i.ibb.co/MGVn49H/D6-A1958-min.jpg",
  ],
  thumbnails: [
    "https://i.ibb.co/Tmw5TB1/D6-A2388-min.jpg",
    "https://i.ibb.co/LdSnF3G/TDP-8761-min.jpg",
    "https://i.ibb.co/vwhqkq6/D6-A2726-min.jpg",
    "https://i.ibb.co/cY34YZD/D6-A2302-min.jpg",
    "https://i.ibb.co/6JSk6Vb/D6-A1782-min.jpg",
    "https://i.ibb.co/L092v5y/TDP-8742-min.jpg",
    "https://i.ibb.co/YPVmgMk/TDP-8751-min.jpg",
    "https://i.ibb.co/mR9bZBV/TDP-8784-min.jpg",
    "https://i.ibb.co/C2Jpm7v/D6-A2713-min.jpg",
    "https://i.ibb.co/ZGv2gTq/D6-A2591-min.jpg",
    "https://i.ibb.co/8DyNRVr/D6-A2463-min.jpg",
    "https://i.ibb.co/pP94HLn/D6-A2244-min.jpg",
    "https://i.ibb.co/FwKh1x7/D6-A2154-min.jpg",
    "https://i.ibb.co/y4qz1z5/D6-A2088-min.jpg",
    "https://i.ibb.co/MsnXJfp/TDP-8932-min.jpg",
    "https://i.ibb.co/J2bx1ms/TDP-8887-min.jpg",
    "https://i.ibb.co/rF6rrZB/TDP-8880-min.jpg",
    "https://i.ibb.co/f8Fd6SV/D6-A2845-min.jpg",
    "https://i.ibb.co/X3sXgVP/D6-A1958-min.jpg",
  ],
};

export default album;
