import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./LanguageButton.scss";

function LanguageButton() {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    if (i18n.language === "en") {
      localStorage.setItem("language", "vi");
      i18n.changeLanguage("vi");
    } else {
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
    }
  };

  const getLanguage = () => {
    return i18n.language === "en" ? "Tiếng Việt" : "English";
  };

  const getToolTip = () => {
    return i18n.language === "en"
      ? "Chuyển sang tiếng Việt"
      : "Switch to English";
  };

  useEffect(() => {
    const language = localStorage.getItem("language");

    if (language) {
      i18n.changeLanguage(language);
    }
  }, [i18n]);

  return (
    <div
      className="language-button"
      onClick={changeLanguage}
      title={getToolTip()}
    >
      {getLanguage()}
    </div>
  );
}

export default LanguageButton;
