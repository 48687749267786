const album = {
  title: 'album1',
  order: [
    'AZ8A7050.jpg',
    'CH5A9838.jpg',
    'CH5A9855.jpg',
    'CH5A9872.jpg',
    'CH5A9831.jpg',
    'CH5A9932.jpg',
    'CH5A9922.jpg',
    'CH5A9955.jpg',
    'AZ8A7056.jpg',
    'AZ8A7062.jpg',
    'AZ8A7068.jpg',
    'AZ8A7072.jpg',
    'CH5A9964.jpg',
    'CH5A9976.jpg',
    'CH5A9985.jpg',
    'CH5A9998.jpg',
    'AZ8A7075.jpg',
    'CH5A0012.jpg',
    'CH5A0014.jpg',
    'CH5A0025.jpg',
    'CH5A0037.jpg',
    'CH5A0044.jpg',
    'CH5A0046.jpg',
    'CH5A0054.jpg',
    'CH5A0064.jpg',
    'CH5A0077.jpg',
    'CH5A0092.jpg',
    'CH5A0101.jpg',
    'CH5A0104.jpg',
    'CH5A0112.jpg',
    'CH5A0117.jpg',
    'CH5A0123.jpg',
    'CH5A0127.jpg',
    'CH5A0130.jpg',
    'CH5A0132.jpg',
    'CH5A0140.jpg',
    'CH5A0152.jpg',
    'CH5A0159.jpg',
    'CH5A0165.jpg',
  ],
  images: [
    'https://i.ibb.co/s6FRyy2/AZ8A7050.jpg',
    'https://i.ibb.co/ZNvd5FV/CH5A9831.jpg',
    'https://i.ibb.co/9HP9L9n/CH5A9838.jpg',
    'https://i.ibb.co/P9jzw4Q/CH5A9855.jpg',
    'https://i.ibb.co/KWmh1HS/CH5A9872.jpg',
    'https://i.ibb.co/M9BbjMZ/CH5A9922.jpg',
    'https://i.ibb.co/VDwXTmd/CH5A9932.jpg',
    'https://i.ibb.co/dPL9Z6V/CH5A9955.jpg',
    'https://i.ibb.co/yhpnh8m/AZ8A7056.jpg',
    'https://i.ibb.co/4YQmcmC/AZ8A7062.jpg',
    'https://i.ibb.co/S3grGR2/AZ8A7068.jpg',
    'https://i.ibb.co/8YfQVP4/AZ8A7072.jpg',
    // ===========================
    'https://i.ibb.co/M8KZybc/CH5A9964.jpg',
    'https://i.ibb.co/C0c8sKd/CH5A9976.jpg',
    'https://i.ibb.co/j5fKFdc/CH5A9985.jpg',
    'https://i.ibb.co/4jf5Z3g/CH5A9998.jpg',
    'https://i.ibb.co/wpvtmK6/AZ8A7075.jpg',
    'https://i.ibb.co/T0h2v3n/CH5A0012.jpg',
    'https://i.ibb.co/2Np38PW/CH5A0014.jpg',
    'https://i.ibb.co/djnTR3X/CH5A0025.jpg',
    'https://i.ibb.co/VqTqM0b/CH5A0037.jpg',
    'https://i.ibb.co/16b6Pxn/CH5A0044.jpg',
    'https://i.ibb.co/Z69Wn3c/CH5A0046.jpg',
    'https://i.ibb.co/zJcQ8r1/CH5A0054.jpg',
    'https://i.ibb.co/mSxHzyf/CH5A0064.jpg',
    'https://i.ibb.co/80yn8xJ/CH5A0077.jpg',
    'https://i.ibb.co/vv9K7Xm/CH5A0092.jpg',
    'https://i.ibb.co/R6WCLFq/CH5A0101.jpg',
    'https://i.ibb.co/C61XvNR/CH5A0104.jpg',
    'https://i.ibb.co/gdxsP0p/CH5A0112.jpg',
    'https://i.ibb.co/ZJd9Qtz/CH5A0117.jpg',
    'https://i.ibb.co/wQf0z6q/CH5A0123.jpg',
    'https://i.ibb.co/DKWLpnn/CH5A0127.jpg',
    'https://i.ibb.co/bFDK2PR/CH5A0130.jpg',
    'https://i.ibb.co/31yS7Ps/CH5A0132.jpg',
    'https://i.ibb.co/jRnQPrG/CH5A0140.jpg',
    'https://i.ibb.co/BBbDdkT/CH5A0152.jpg',
    'https://i.ibb.co/8dHgYyq/CH5A0159.jpg',
    'https://i.ibb.co/dbm6SNr/CH5A0165.jpg',
    'https://i.ibb.co/4Sg7xCc/CH5A0169.jpg',
    'https://i.ibb.co/Dg5N12B/CH5A0173.jpg',
    'https://i.ibb.co/12vMSSL/CH5A0250.jpg',
    // ===========================
    'https://i.ibb.co/QNg3Lx6/CH5A0191.jpg',
    'https://i.ibb.co/x5bY96f/CH5A0200.jpg',
    'https://i.ibb.co/txT0zDZ/CH5A0212.jpg',
    'https://i.ibb.co/sCZwBQq/CH5A0227.jpg',
    'https://i.ibb.co/qjp5bQB/CH5A0231.jpg',
    'https://i.ibb.co/j8Dxc03/AZ8A7082.jpg',
    'https://i.ibb.co/wsPxKtG/AZ8A7086.jpg',
    'https://i.ibb.co/G2B9Ly9/AZ8A7114.jpg',
    'https://i.ibb.co/k32qLXQ/AZ8A7123.jpg',
    'https://i.ibb.co/YXryHWt/AZ8A7127.jpg',
    'https://i.ibb.co/wBV0fd5/AZ8A7132.jpg',
    'https://i.ibb.co/kJSGJTK/AZ8A7137.jpg',
    'https://i.ibb.co/jyNTbtz/AZ8A7146.jpg',
    'https://i.ibb.co/fFbXcTM/AZ8A7153.jpg',
    'https://i.ibb.co/zRShpSV/AZ8A7157.jpg',
    'https://i.ibb.co/z547CsF/AZ8A7180.jpg',
    'https://i.ibb.co/LSDJKyj/AZ8A7197.jpg',

    'https://i.ibb.co/m9ZFtM2/CH5A0271.jpg',
    'https://i.ibb.co/0rk2v9d/CH5A0281.jpg',
    'https://i.ibb.co/VgwVB59/CH5A0297.jpg',
    'https://i.ibb.co/19m6dsX/CH5A0291.jpg',
    'https://i.ibb.co/wBDHvWv/CH5A0322.jpg',
    'https://i.ibb.co/5Wv4jxw/CH5A0299.jpg',
    'https://i.ibb.co/Qk2MZFB/CH5A0327.jpg',
    'https://i.ibb.co/SmSC95X/CH5A0330.jpg',
    'https://i.ibb.co/RvXycLc/CH5A0334.jpg',
    'https://i.ibb.co/rwv0vVx/CH5A0348.jpg',
    'https://i.ibb.co/hYWdWfM/CH5A0352.jpg',
    'https://i.ibb.co/bH81zCz/CH5A0356.jpg',
    'https://i.ibb.co/yQztGz5/CH5A0381.jpg',
    'https://i.ibb.co/6bj5j51/CH5A0366.jpg',
    'https://i.ibb.co/kQy71yC/CH5A0388.jpg',
    'https://i.ibb.co/yfmC5Fq/CH5A0393.jpg',
    'https://i.ibb.co/Wpb7tXH/CH5A0406.jpg',
    'https://i.ibb.co/XVSLKsn/CH5A0410.jpg',
    'https://i.ibb.co/nknQf1W/CH5A0422.jpg',
    'https://i.ibb.co/167HM59/CH5A0427.jpg',
    'https://i.ibb.co/vZvYgRh/CH5A0431.jpg',
    'https://i.ibb.co/rf8zJmk/CH5A0444.jpg',
    'https://i.ibb.co/bWKqGLn/CH5A0457.jpg',
    'https://i.ibb.co/wd4L1Tb/CH5A0463.jpg',
    'https://i.ibb.co/KjXkXsk/CH5A0476.jpg',
    'https://i.ibb.co/mC31yJv/CH5A0472.jpg',
    'https://i.ibb.co/DgzPHkG/CH5A0480.jpg',
    'https://i.ibb.co/0ntSt2d/CH5A0509.jpg',
    'https://i.ibb.co/YXmDN1m/CH5A0563.jpg',
    'https://i.ibb.co/71xDyM8/CH5A0572.jpg',
    'https://i.ibb.co/mJTMYgL/CH5A0578.jpg',
    'https://i.ibb.co/pQgJvK5/CH5A0582.jpg',
    'https://i.ibb.co/xmHTdy2/CH5A0585.jpg',
    'https://i.ibb.co/ZfyKq9b/CH5A0587.jpg',
    'https://i.ibb.co/7b1Yy4r/CH5A0620.jpg',
    'https://i.ibb.co/9qV4Ywb/CH5A0621.jpg',
    'https://i.ibb.co/0ZmHbjJ/CH5A0632.jpg',
    'https://i.ibb.co/TYX0CKh/CH5A0628.jpg',
    'https://i.ibb.co/D12GNgF/CH5A0641.jpg',
    'https://i.ibb.co/7VJZqBt/CH5A0643.jpg',
    'https://i.ibb.co/DrcShYc/CH5A0652.jpg',
  ],
  thumbnails: [
    'https://i.ibb.co/wMVVNKh/AZ8A7056.jpg',
    'https://i.ibb.co/KD16x2B/AZ8A7050.jpg',
    'https://i.ibb.co/BZSqMvg/AZ8A7062.jpg',
    'https://i.ibb.co/7SztG29/AZ8A7068.jpg',
    'https://i.ibb.co/Dgzcrrc/AZ8A7072.jpg',
    'https://i.ibb.co/HzDgc4w/AZ8A7075.jpg',
    'https://i.ibb.co/kQ2XKg1/AZ8A7082.jpg',
    'https://i.ibb.co/3RW2MmR/AZ8A7086.jpg',
    'https://i.ibb.co/ZLjjRm7/AZ8A7114.jpg',
    'https://i.ibb.co/PtPV5Jm/AZ8A7123.jpg',
    'https://i.ibb.co/6bpf5GK/AZ8A7127.jpg',
    'https://i.ibb.co/QCNmStm/AZ8A7132.jpg',
    'https://i.ibb.co/9v3Jb8q/AZ8A7137.jpg',
    'https://i.ibb.co/phDFhhN/AZ8A7146.jpg',
    'https://i.ibb.co/KrCQBcR/AZ8A7153.jpg',
    'https://i.ibb.co/TYvdQY9/AZ8A7157.jpg',
    'https://i.ibb.co/Tgt7633/AZ8A7180.jpg',
    'https://i.ibb.co/ct6Knfd/AZ8A7197.jpg',
    'https://i.ibb.co/6nfWKdn/CH5A0012.jpg',
    'https://i.ibb.co/bvYStDc/CH5A0014.jpg',
    'https://i.ibb.co/FhXDV1r/CH5A0025.jpg',
    'https://i.ibb.co/j8GZ8q6/CH5A0037.jpg',
    'https://i.ibb.co/BNsBcPn/CH5A0044.jpg',
    'https://i.ibb.co/4tXz3fk/CH5A0046.jpg',
    'https://i.ibb.co/gD08N2v/CH5A0054.jpg',
    'https://i.ibb.co/kDhg9k4/CH5A0064.jpg',
    'https://i.ibb.co/M8fsnJP/CH5A0077.jpg',
    'https://i.ibb.co/p46d8H4/CH5A0092.jpg',
    'https://i.ibb.co/dMCVMnx/CH5A0101.jpg',
    'https://i.ibb.co/f8LwW0Y/CH5A0104.jpg',
    'https://i.ibb.co/C8MgpqC/CH5A0112.jpg',
    'https://i.ibb.co/c2XNbPb/CH5A0117.jpg',
    'https://i.ibb.co/5B32c9X/CH5A0123.jpg',
    'https://i.ibb.co/T4M5yNk/CH5A0127.jpg',
    'https://i.ibb.co/JxnBffC/CH5A0130.jpg',
    'https://i.ibb.co/4F19x7x/CH5A0132.jpg',
    'https://i.ibb.co/6J6tGS8/CH5A0152.jpg',
    'https://i.ibb.co/ZLZdSj0/CH5A0140.jpg',
    'https://i.ibb.co/b2djBzG/CH5A0159.jpg',
    'https://i.ibb.co/JrVSDyj/CH5A0165.jpg',
    'https://i.ibb.co/Y3Ktxht/CH5A0169.jpg',
    'https://i.ibb.co/PFVm05k/CH5A0173.jpg',
    'https://i.ibb.co/r3bHFY6/CH5A0191.jpg',
    'https://i.ibb.co/d51Fxd7/CH5A0200.jpg',
    'https://i.ibb.co/yVj7hr5/CH5A0212.jpg',
    'https://i.ibb.co/CBDHHfp/CH5A0227.jpg',
    'https://i.ibb.co/FHNS2pM/CH5A0231.jpg',
    'https://i.ibb.co/H29ZHCk/CH5A0250.jpg',
    'https://i.ibb.co/z5VYcJR/CH5A0271.jpg',
    'https://i.ibb.co/HzCbxd1/CH5A0281.jpg',
    'https://i.ibb.co/ZfjCjyJ/CH5A0291.jpg',
    'https://i.ibb.co/cvCD6HD/CH5A0297.jpg',
    'https://i.ibb.co/PGYxC2z/CH5A0299.jpg',
    'https://i.ibb.co/DRKBTtz/CH5A0322.jpg',
    'https://i.ibb.co/z7n7hYN/CH5A0327.jpg',
    'https://i.ibb.co/RSjXpV8/CH5A0330.jpg',
    'https://i.ibb.co/cvm5xjS/CH5A0334.jpg',
    'https://i.ibb.co/DgJBbCC/CH5A0348.jpg',
    'https://i.ibb.co/kSQJ3VH/CH5A0352.jpg',
    'https://i.ibb.co/gwLrJsF/CH5A0356.jpg',
    'https://i.ibb.co/GcGqhhD/CH5A0381.jpg',
    'https://i.ibb.co/Tmc6PX6/CH5A0366.jpg',
    'https://i.ibb.co/ZYgcrcX/CH5A0388.jpg',
    'https://i.ibb.co/WGtVZnP/CH5A0393.jpg',
    'https://i.ibb.co/0yRZs3Z/CH5A0406.jpg',
    'https://i.ibb.co/k0knKr4/CH5A0410.jpg',
    'https://i.ibb.co/25D5nfW/CH5A0422.jpg',
    'https://i.ibb.co/qyjvv3v/CH5A0427.jpg',
    'https://i.ibb.co/HDBC6RN/CH5A0431.jpg',
    'https://i.ibb.co/dJVNqb0/CH5A0444.jpg',
    'https://i.ibb.co/6vJZ8tC/CH5A0457.jpg',
    'https://i.ibb.co/qCzgnMp/CH5A0463.jpg',
    'https://i.ibb.co/3dWp7nQ/CH5A0472.jpg',
    'https://i.ibb.co/LRW9fxZ/CH5A0476.jpg',
    'https://i.ibb.co/J5fTnSb/CH5A0480.jpg',
    'https://i.ibb.co/sJ04RpL/CH5A0509.jpg',
    'https://i.ibb.co/d26W1sj/CH5A0563.jpg',
    'https://i.ibb.co/bs8Gt9c/CH5A0572.jpg',
    'https://i.ibb.co/Z6cCQPR/CH5A0578.jpg',
    'https://i.ibb.co/2cRWJ66/CH5A0582.jpg',
    'https://i.ibb.co/fvY91Kt/CH5A0585.jpg',
    'https://i.ibb.co/B4JJSX1/CH5A0587.jpg',
    'https://i.ibb.co/mzMgjtn/CH5A0620.jpg',
    'https://i.ibb.co/8c32Srx/CH5A0621.jpg',
    'https://i.ibb.co/w0dwzFX/CH5A0632.jpg',
    'https://i.ibb.co/Jn9gGPT/CH5A0628.jpg',
    'https://i.ibb.co/0GXHTC4/CH5A0641.jpg',
    'https://i.ibb.co/LZKtJLZ/CH5A0643.jpg',
    'https://i.ibb.co/17JfrWp/CH5A0652.jpg',
    'https://i.ibb.co/KbGCx8P/CH5A9831.jpg',
    'https://i.ibb.co/tZKKQqb/CH5A9838.jpg',
    'https://i.ibb.co/MktWdzs/CH5A9855.jpg',
    'https://i.ibb.co/SyW75tn/CH5A9872.jpg',
    'https://i.ibb.co/BNxhLgC/CH5A9922.jpg',
    'https://i.ibb.co/KKwnm5z/CH5A9932.jpg',
    'https://i.ibb.co/y6QNw5m/CH5A9955.jpg',
    'https://i.ibb.co/8jbmGvJ/CH5A9964.jpg',
    'https://i.ibb.co/cJGdPHn/CH5A9976.jpg',
    'https://i.ibb.co/mHgtjJT/CH5A9985.jpg',
    'https://i.ibb.co/KXrDj8q/CH5A9998.jpg',
  ],
};

export default album;
