import React from "react";
import "./Slider.scss";
import { useTranslation } from "react-i18next";

import header from "../../../images/header.jpg";
import line from "../../../images/floral_line.png";

const Slider = () => {
  const { t } = useTranslation();

  return (
    <div className="slider" style={{ backgroundImage: `url(${header})` }}>
      <div className="overlay"></div>
      <div className="overlay-full"></div>
      <div className="message">
        <img src={line} alt="floral_line" />
        <div className="text text--sub">{t("title")}</div>
        <div className="text text--main">Đạt &nbsp;& Quỳnh</div>
        <img src={line} alt="floral_line" />
      </div>
    </div>
  );
};

export default Slider;
