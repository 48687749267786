const album = {
  title: "guestWelcoming",
  images: [
    "https://i.ibb.co/VHmjZs3/IMG-5138.jpg",
    "https://i.ibb.co/xmptp5z/IMG-5141.jpg",
    "https://i.ibb.co/SQpyNfg/IMG-5143.jpg",
    "https://i.ibb.co/ZTmHhRR/IMG-5148.jpg",
    "https://i.ibb.co/JHGW2LP/IMG-5153.jpg",
    "https://i.ibb.co/6g43qxj/IMG-5157.jpg",
    "https://i.ibb.co/3CDHmbq/IMG-5162.jpg",
    "https://i.ibb.co/XpqYH5q/DSCF0436.jpg",
    "https://i.ibb.co/qr0XzYh/IMG-5164.jpg",
    "https://i.ibb.co/DQB01YX/IMG-5167.jpg",
    "https://i.ibb.co/ry0LLGH/IMG-5170.jpg",
    "https://i.ibb.co/XptWFPk/DSCF0471.jpg",
    "https://i.ibb.co/84c2q27/DSCF0485.jpg",
    "https://i.ibb.co/fvwp3Ry/DSCF0495.jpg",
    "https://i.ibb.co/grKbfN5/PSL21641.jpg",
    "https://i.ibb.co/hHdkjw8/DSCF0490.jpg",
    "https://i.ibb.co/SPfWLTy/DSCF0493.jpg",
    "https://i.ibb.co/tppmBQr/DSCF0502.jpg",
    "https://i.ibb.co/fC0YRXH/DSCF0506.jpg",
    "https://i.ibb.co/qYFKd6r/DSCF0514.jpg",
    "https://i.ibb.co/jD0tR1j/DSCF0499.jpg",
    "https://i.ibb.co/bmdsT5y/IMG-5183.jpg",
    "https://i.ibb.co/tbGZfJ9/IMG-5180.jpg",
    "https://i.ibb.co/FmYwc7V/IMG-5185.jpg",
    "https://i.ibb.co/JCJwP1T/IMG-5188.jpg",
    "https://i.ibb.co/kMTBQrX/IMG-5190.jpg",
    "https://i.ibb.co/KNXjkzn/IMG-5193.jpg",
    "https://i.ibb.co/9hQ7FvB/IMG-5195.jpg",
    "https://i.ibb.co/8X7yc4q/IMG-5200.jpg",
    "https://i.ibb.co/3W3bD1M/IMG-5211.jpg",
    "https://i.ibb.co/ZxtrtVw/IMG-5214.jpg",
    "https://i.ibb.co/zfxPqWQ/IMG-5218.jpg",
    "https://i.ibb.co/FgpLKXF/DSCF0518.jpg",
    "https://i.ibb.co/gb1SCwy/PSL21646.jpg",
    "https://i.ibb.co/19K1hWq/PSL21643.jpg",
    "https://i.ibb.co/7Np4zDX/PSL21648.jpg",
    "https://i.ibb.co/2vwYmYG/PSL21650.jpg",
    "https://i.ibb.co/TWLbVLH/DSCF0537.jpg",
    "https://i.ibb.co/dPV9gN4/DSCF0530.jpg",
    "https://i.ibb.co/X7fzcdL/DSCF0542.jpg",
    "https://i.ibb.co/Jx4G278/PSL21662.jpg",
    "https://i.ibb.co/b2njSbd/PSL21654.jpg",
    "https://i.ibb.co/V9CbgQt/DSCF0521.jpg",
    "https://i.ibb.co/nchWGzk/DSCF0545.jpg",
    "https://i.ibb.co/1QJQ7S9/DSCF0548.jpg",
    "https://i.ibb.co/wNcTgK8/DSCF0554.jpg",
    "https://i.ibb.co/Dr71dVW/DSCF0557.jpg",
    "https://i.ibb.co/vBYzmzw/DSCF0560.jpg",
    "https://i.ibb.co/9hHQ9BJ/DSCF0564.jpg",
    "https://i.ibb.co/2kWsyzv/DSCF0569.jpg",
    "https://i.ibb.co/wBDHxXh/DSCF0571.jpg",
    "https://i.ibb.co/z8MpTVw/DSCF0587.jpg",
    "https://i.ibb.co/d0qSc9z/DSCF0590.jpg",
    "https://i.ibb.co/LJ65mGL/DSCF0593.jpg",
    "https://i.ibb.co/WtZjgT9/DSCF0599.jpg",
    "https://i.ibb.co/mHKSpGd/DSCF0601.jpg",
    "https://i.ibb.co/gmbBxYf/DSCF0612.jpg",
    "https://i.ibb.co/LtncGL2/DSCF0614.jpg",
    "https://i.ibb.co/ZHngMgn/DSCF0622.jpg",
    "https://i.ibb.co/6ZLMDSP/DSCF0625.jpg",
    "https://i.ibb.co/44NvjSJ/DSCF0629.jpg",
    "https://i.ibb.co/MfXbrpp/DSCF0631.jpg",
    "https://i.ibb.co/8PNB3NN/DSCF0633.jpg",
    "https://i.ibb.co/sWJzqyq/DSCF0636.jpg",
    "https://i.ibb.co/DrdFFCz/DSCF0640.jpg",
    "https://i.ibb.co/wLHbmQs/IMG-5223.jpg",
    "https://i.ibb.co/xm26SW8/IMG-5225.jpg",
    "https://i.ibb.co/2jnkpNf/IMG-5226.jpg",
    "https://i.ibb.co/ZJCQxYq/IMG-5228.jpg",
    "https://i.ibb.co/RgzFsTv/IMG-5230.jpg",
    "https://i.ibb.co/YXycvwd/IMG-5235.jpg",
    "https://i.ibb.co/VmSvmZY/IMG-5237.jpg",
    "https://i.ibb.co/QQDvBJ1/IMG-5240.jpg",
    "https://i.ibb.co/CKWCfPF/IMG-5243.jpg",
    "https://i.ibb.co/WWNNbwh/IMG-5245.jpg",
    "https://i.ibb.co/6Zbfq9d/IMG-5247.jpg",
    "https://i.ibb.co/yFPSYN9/IMG-5249.jpg",
    "https://i.ibb.co/c3VrZhY/IMG-5251.jpg",
    "https://i.ibb.co/89R2Hjw/IMG-5256.jpg",
    "https://i.ibb.co/sHKMjB2/IMG-5255.jpg",

    "https://i.ibb.co/p0cp9PM/IMG-5267.jpg",
    "https://i.ibb.co/BtNdhRP/IMG-5261.jpg",
    "https://i.ibb.co/zxTXCFB/IMG-5262.jpg",
    "https://i.ibb.co/p0VbhV5/IMG-5259.jpg",
    "https://i.ibb.co/cNDn2Cy/IMG-5268.jpg",
    "https://i.ibb.co/YfVPpvN/IMG-5271.jpg",
    "https://i.ibb.co/W389jWC/IMG-5273.jpg",
    "https://i.ibb.co/zQPMsb4/IMG-5276.jpg",
    "https://i.ibb.co/X8fg57T/IMG-5277.jpg",
    "https://i.ibb.co/3hLR5fB/IMG-5279.jpg",
    "https://i.ibb.co/34ZwySR/IMG-5282.jpg",
    "https://i.ibb.co/DYLDFdR/IMG-5285.jpg",
    "https://i.ibb.co/5rNtSHY/IMG-5287.jpg",
    "https://i.ibb.co/qC09D2w/IMG-5289.jpg",
    "https://i.ibb.co/hLVrPh4/IMG-5293.jpg",
    "https://i.ibb.co/2FG7s3C/IMG-5294.jpg",
    "https://i.ibb.co/D1YXfVq/IMG-5296.jpg",
    "https://i.ibb.co/ryCN9MQ/IMG-5300.jpg",
    "https://i.ibb.co/cv92mw4/IMG-5303.jpg",
    "https://i.ibb.co/sjdFNYW/IMG-5308.jpg",
    "https://i.ibb.co/b3gyywK/IMG-5312.jpg",
    "https://i.ibb.co/60xWLxK/IMG-5313.jpg",
    "https://i.ibb.co/6PbTyYW/IMG-5315.jpg",
    "https://i.ibb.co/C6wnxQV/IMG-5318.jpg",
    "https://i.ibb.co/zRFXJ5B/IMG-5321.jpg",
    "https://i.ibb.co/x1TtnY6/IMG-5323.jpg",
    "https://i.ibb.co/wdktnT9/IMG-5328.jpg",
    "https://i.ibb.co/SxbR7vL/IMG-5336.jpg",
    "https://i.ibb.co/Wp6SX4H/IMG-5342.jpg",
    "https://i.ibb.co/sQnL4Z8/IMG-5344.jpg",
    "https://i.ibb.co/pJ9bNkK/IMG-5347.jpg",
    "https://i.ibb.co/yyRW3kb/IMG-5348.jpg",
    "https://i.ibb.co/d5z91zM/IMG-5349.jpg",
    "https://i.ibb.co/n30G5b8/IMG-5351.jpg",
    "https://i.ibb.co/ZKXXB6Z/IMG-5353.jpg",
    "https://i.ibb.co/56Wz1Ns/IMG-5358.jpg",
    "https://i.ibb.co/Hzr3Z28/IMG-5364.jpg",
    "https://i.ibb.co/QrBBvvN/IMG-5371.jpg",
    "https://i.ibb.co/HGCtV5z/IMG-5374.jpg",
    "https://i.ibb.co/4gz15xV/IMG-5378.jpg",
    "https://i.ibb.co/WP9hPs1/PSL21671.jpg",
    "https://i.ibb.co/LY3c7pQ/PSL21672.jpg",
    "https://i.ibb.co/7zvwFqm/PSL21674.jpg",
    "https://i.ibb.co/7W1KLrL/PSL21683.jpg",
    "https://i.ibb.co/F4QrqNQ/PSL21687.jpg",
    "https://i.ibb.co/CQkf17J/PSL21700.jpg",
    "https://i.ibb.co/Rg2WbJ6/PSL21706.jpg",
    "https://i.ibb.co/vZn3x2q/PSL21708.jpg",
    "https://i.ibb.co/ZmQyvrq/PSL21727.jpg",
    "https://i.ibb.co/681s5kS/PSL21729.jpg",
    "https://i.ibb.co/TWC2DDM/PSL21728.jpg",
    "https://i.ibb.co/ZYGdfCZ/PSL21730.jpg",
    "https://i.ibb.co/KxNf4w5/PSL21736.jpg",
    "https://i.ibb.co/PhQMxTr/PSL21746.jpg",
    "https://i.ibb.co/CHK01yT/PSL21750.jpg",
    "https://i.ibb.co/93m4hK8/PSL21740.jpg",
    "https://i.ibb.co/Nx31Gh3/IMG-5325.jpg",
    "https://i.ibb.co/m6dwBJG/PSL21770.jpg",
    "https://i.ibb.co/PgBXdGy/PSL21772.jpg",
    "https://i.ibb.co/HGJr27d/PSL21782.jpg",
    "https://i.ibb.co/Y8PHJp0/PSL21785.jpg",
    "https://i.ibb.co/Zm3sgPL/PSL21787.jpg",
    "https://i.ibb.co/r72MzJG/PSL21790.jpg",
    "https://i.ibb.co/99B5nxP/PSL21793.jpg",
    "https://i.ibb.co/q156m9q/PSL21794.jpg",
    "https://i.ibb.co/xJMKXpK/PSL21795.jpg",
    "https://i.ibb.co/hmfcz4k/PSL21796.jpg",
    "https://i.ibb.co/KrMcMYx/PSL21802.jpg",
    "https://i.ibb.co/SnyY5f9/PSL21811.jpg",
    "https://i.ibb.co/0nnWH10/PSL21825.jpg",
    "https://i.ibb.co/cyQX68v/PSL21830.jpg",
    "https://i.ibb.co/PZTyz21/PSL21835.jpg",
  ],

  thumbnails: [
    "https://i.ibb.co/XpqYH5q/DSCF0436.jpg",
    "https://i.ibb.co/VHmjZs3/IMG-5138.jpg",
    "https://i.ibb.co/xmptp5z/IMG-5141.jpg",
    "https://i.ibb.co/SQpyNfg/IMG-5143.jpg",
    "https://i.ibb.co/ZTmHhRR/IMG-5148.jpg",
    "https://i.ibb.co/JHGW2LP/IMG-5153.jpg",
    "https://i.ibb.co/6g43qxj/IMG-5157.jpg",
    "https://i.ibb.co/3CDHmbq/IMG-5162.jpg",
    "https://i.ibb.co/qr0XzYh/IMG-5164.jpg",
    "https://i.ibb.co/DQB01YX/IMG-5167.jpg",
    "https://i.ibb.co/ry0LLGH/IMG-5170.jpg",
    "https://i.ibb.co/XptWFPk/DSCF0471.jpg",
    "https://i.ibb.co/84c2q27/DSCF0485.jpg",
    "https://i.ibb.co/hHdkjw8/DSCF0490.jpg",
    "https://i.ibb.co/SPfWLTy/DSCF0493.jpg",
    "https://i.ibb.co/fvwp3Ry/DSCF0495.jpg",
    "https://i.ibb.co/jD0tR1j/DSCF0499.jpg",
    "https://i.ibb.co/tppmBQr/DSCF0502.jpg",
    "https://i.ibb.co/fC0YRXH/DSCF0506.jpg",
    "https://i.ibb.co/qYFKd6r/DSCF0514.jpg",
    "https://i.ibb.co/bmdsT5y/IMG-5183.jpg",
    "https://i.ibb.co/tbGZfJ9/IMG-5180.jpg",
    "https://i.ibb.co/FmYwc7V/IMG-5185.jpg",
    "https://i.ibb.co/JCJwP1T/IMG-5188.jpg",
    "https://i.ibb.co/kMTBQrX/IMG-5190.jpg",
    "https://i.ibb.co/KNXjkzn/IMG-5193.jpg",
    "https://i.ibb.co/9hQ7FvB/IMG-5195.jpg",
    "https://i.ibb.co/8X7yc4q/IMG-5200.jpg",
    "https://i.ibb.co/3W3bD1M/IMG-5211.jpg",
    "https://i.ibb.co/ZxtrtVw/IMG-5214.jpg",
    "https://i.ibb.co/zfxPqWQ/IMG-5218.jpg",
    "https://i.ibb.co/FgpLKXF/DSCF0518.jpg",
    "https://i.ibb.co/gb1SCwy/PSL21646.jpg",
    "https://i.ibb.co/19K1hWq/PSL21643.jpg",
    "https://i.ibb.co/7Np4zDX/PSL21648.jpg",
    "https://i.ibb.co/2vwYmYG/PSL21650.jpg",
    "https://i.ibb.co/TWLbVLH/DSCF0537.jpg",
    "https://i.ibb.co/dPV9gN4/DSCF0530.jpg",
    "https://i.ibb.co/X7fzcdL/DSCF0542.jpg",
    "https://i.ibb.co/Jx4G278/PSL21662.jpg",
    "https://i.ibb.co/b2njSbd/PSL21654.jpg",
    "https://i.ibb.co/V9CbgQt/DSCF0521.jpg",
    "https://i.ibb.co/nchWGzk/DSCF0545.jpg",
    "https://i.ibb.co/1QJQ7S9/DSCF0548.jpg",
    "https://i.ibb.co/wNcTgK8/DSCF0554.jpg",
    "https://i.ibb.co/Dr71dVW/DSCF0557.jpg",
    "https://i.ibb.co/vBYzmzw/DSCF0560.jpg",
    "https://i.ibb.co/9hHQ9BJ/DSCF0564.jpg",
    "https://i.ibb.co/2kWsyzv/DSCF0569.jpg",
    "https://i.ibb.co/wBDHxXh/DSCF0571.jpg",
    "https://i.ibb.co/z8MpTVw/DSCF0587.jpg",
    "https://i.ibb.co/d0qSc9z/DSCF0590.jpg",
    "https://i.ibb.co/LJ65mGL/DSCF0593.jpg",
    "https://i.ibb.co/WtZjgT9/DSCF0599.jpg",
    "https://i.ibb.co/mHKSpGd/DSCF0601.jpg",
    "https://i.ibb.co/gmbBxYf/DSCF0612.jpg",
    "https://i.ibb.co/LtncGL2/DSCF0614.jpg",
    "https://i.ibb.co/ZHngMgn/DSCF0622.jpg",
    "https://i.ibb.co/6ZLMDSP/DSCF0625.jpg",
    "https://i.ibb.co/44NvjSJ/DSCF0629.jpg",
    "https://i.ibb.co/MfXbrpp/DSCF0631.jpg",
    "https://i.ibb.co/8PNB3NN/DSCF0633.jpg",
    "https://i.ibb.co/sWJzqyq/DSCF0636.jpg",
    "https://i.ibb.co/DrdFFCz/DSCF0640.jpg",
    "https://i.ibb.co/wLHbmQs/IMG-5223.jpg",
    "https://i.ibb.co/xm26SW8/IMG-5225.jpg",
    "https://i.ibb.co/2jnkpNf/IMG-5226.jpg",
    "https://i.ibb.co/ZJCQxYq/IMG-5228.jpg",
    "https://i.ibb.co/RgzFsTv/IMG-5230.jpg",
    "https://i.ibb.co/YXycvwd/IMG-5235.jpg",
    "https://i.ibb.co/VmSvmZY/IMG-5237.jpg",
    "https://i.ibb.co/QQDvBJ1/IMG-5240.jpg",
    "https://i.ibb.co/CKWCfPF/IMG-5243.jpg",
    "https://i.ibb.co/WWNNbwh/IMG-5245.jpg",
    "https://i.ibb.co/6Zbfq9d/IMG-5247.jpg",
    "https://i.ibb.co/yFPSYN9/IMG-5249.jpg",
    "https://i.ibb.co/c3VrZhY/IMG-5251.jpg",
    "https://i.ibb.co/sHKMjB2/IMG-5255.jpg",
    "https://i.ibb.co/89R2Hjw/IMG-5256.jpg",
    "https://i.ibb.co/p0VbhV5/IMG-5259.jpg",
    "https://i.ibb.co/BtNdhRP/IMG-5261.jpg",
    "https://i.ibb.co/zxTXCFB/IMG-5262.jpg",
    "https://i.ibb.co/p0cp9PM/IMG-5267.jpg",
    "https://i.ibb.co/cNDn2Cy/IMG-5268.jpg",
    "https://i.ibb.co/YfVPpvN/IMG-5271.jpg",
    "https://i.ibb.co/W389jWC/IMG-5273.jpg",
    "https://i.ibb.co/zQPMsb4/IMG-5276.jpg",
    "https://i.ibb.co/X8fg57T/IMG-5277.jpg",
    "https://i.ibb.co/3hLR5fB/IMG-5279.jpg",
    "https://i.ibb.co/34ZwySR/IMG-5282.jpg",
    "https://i.ibb.co/DYLDFdR/IMG-5285.jpg",
    "https://i.ibb.co/5rNtSHY/IMG-5287.jpg",
    "https://i.ibb.co/qC09D2w/IMG-5289.jpg",
    "https://i.ibb.co/hLVrPh4/IMG-5293.jpg",
    "https://i.ibb.co/2FG7s3C/IMG-5294.jpg",
    "https://i.ibb.co/D1YXfVq/IMG-5296.jpg",
    "https://i.ibb.co/ryCN9MQ/IMG-5300.jpg",
    "https://i.ibb.co/cv92mw4/IMG-5303.jpg",
    "https://i.ibb.co/sjdFNYW/IMG-5308.jpg",
    "https://i.ibb.co/b3gyywK/IMG-5312.jpg",
    "https://i.ibb.co/60xWLxK/IMG-5313.jpg",
    "https://i.ibb.co/6PbTyYW/IMG-5315.jpg",
    "https://i.ibb.co/C6wnxQV/IMG-5318.jpg",
    "https://i.ibb.co/zRFXJ5B/IMG-5321.jpg",
    "https://i.ibb.co/x1TtnY6/IMG-5323.jpg",
    "https://i.ibb.co/Nx31Gh3/IMG-5325.jpg",
    "https://i.ibb.co/wdktnT9/IMG-5328.jpg",
    "https://i.ibb.co/SxbR7vL/IMG-5336.jpg",
    "https://i.ibb.co/Wp6SX4H/IMG-5342.jpg",
    "https://i.ibb.co/sQnL4Z8/IMG-5344.jpg",
    "https://i.ibb.co/pJ9bNkK/IMG-5347.jpg",
    "https://i.ibb.co/yyRW3kb/IMG-5348.jpg",
    "https://i.ibb.co/d5z91zM/IMG-5349.jpg",
    "https://i.ibb.co/n30G5b8/IMG-5351.jpg",
    "https://i.ibb.co/ZKXXB6Z/IMG-5353.jpg",
    "https://i.ibb.co/56Wz1Ns/IMG-5358.jpg",
    "https://i.ibb.co/Hzr3Z28/IMG-5364.jpg",
    "https://i.ibb.co/QrBBvvN/IMG-5371.jpg",
    "https://i.ibb.co/HGCtV5z/IMG-5374.jpg",
    "https://i.ibb.co/4gz15xV/IMG-5378.jpg",
    "https://i.ibb.co/KXwTrhx/PSL21666.jpg",
    "https://i.ibb.co/WP9hPs1/PSL21671.jpg",
    "https://i.ibb.co/LY3c7pQ/PSL21672.jpg",
    "https://i.ibb.co/7zvwFqm/PSL21674.jpg",
    "https://i.ibb.co/7W1KLrL/PSL21683.jpg",
    "https://i.ibb.co/F4QrqNQ/PSL21687.jpg",
    "https://i.ibb.co/CQkf17J/PSL21700.jpg",
    "https://i.ibb.co/Rg2WbJ6/PSL21706.jpg",
    "https://i.ibb.co/vZn3x2q/PSL21708.jpg",
    "https://i.ibb.co/ZmQyvrq/PSL21727.jpg",
    "https://i.ibb.co/681s5kS/PSL21729.jpg",
    "https://i.ibb.co/TWC2DDM/PSL21728.jpg",
    "https://i.ibb.co/ZYGdfCZ/PSL21730.jpg",
    "https://i.ibb.co/KxNf4w5/PSL21736.jpg",
    "https://i.ibb.co/93m4hK8/PSL21740.jpg",
    "https://i.ibb.co/PhQMxTr/PSL21746.jpg",
    "https://i.ibb.co/CHK01yT/PSL21750.jpg",
    "https://i.ibb.co/m6dwBJG/PSL21770.jpg",
    "https://i.ibb.co/PgBXdGy/PSL21772.jpg",
    "https://i.ibb.co/HGJr27d/PSL21782.jpg",
    "https://i.ibb.co/Y8PHJp0/PSL21785.jpg",
    "https://i.ibb.co/Zm3sgPL/PSL21787.jpg",
    "https://i.ibb.co/r72MzJG/PSL21790.jpg",
    "https://i.ibb.co/99B5nxP/PSL21793.jpg",
    "https://i.ibb.co/q156m9q/PSL21794.jpg",
    "https://i.ibb.co/xJMKXpK/PSL21795.jpg",
    "https://i.ibb.co/hmfcz4k/PSL21796.jpg",
    "https://i.ibb.co/KrMcMYx/PSL21802.jpg",
    "https://i.ibb.co/SnyY5f9/PSL21811.jpg",
    "https://i.ibb.co/0nnWH10/PSL21825.jpg",
    "https://i.ibb.co/W0GgmP1/PSL21827.jpg",
    "https://i.ibb.co/cyQX68v/PSL21830.jpg",
    "https://i.ibb.co/PZTyz21/PSL21835.jpg",
  ],
};

export default album;
