const album = {
  title: "decorations",
  images: [
    "https://i.ibb.co/hB5SVV5/IMG-4612.jpg",
    "https://i.ibb.co/CWrJyK0/TIM07864.jpg",
    "https://i.ibb.co/6gM6Wjf/TIM07867.jpg",
    "https://i.ibb.co/qmhnW5D/IMG-4668.jpg",
    "https://i.ibb.co/9NfgzzF/PSL21126.jpg",
    "https://i.ibb.co/FbYhWnV/DSCF0012.jpg",
    "https://i.ibb.co/FKj4fnS/IMG-4666.jpg",

    "https://i.ibb.co/X2k0Rf1/PSL21185.jpg",
    "https://i.ibb.co/thv4j7h/PSL21190.jpg",
    "https://i.ibb.co/pPsXSTy/PSL21187.jpg",
    "https://i.ibb.co/2M5phgQ/PSL21188.jpg",

    "https://i.ibb.co/7zpY1sV/PSL21194.jpg",
    "https://i.ibb.co/BnKbGgh/PSL21199.jpg",
    "https://i.ibb.co/YZD6V1Y/IMG-5097.jpg",
    "https://i.ibb.co/3c4KMbH/IMG-5095.jpg",
    "https://i.ibb.co/hgwvrFg/IMG-5096.jpg",

    "https://i.ibb.co/2FvFY3V/TIM07915.jpg",
    "https://i.ibb.co/cJgc1jq/TIM07908.jpg",
    "https://i.ibb.co/r2RCbmb/TIM07910.jpg",
    "https://i.ibb.co/X41p8Ys/TIM07911.jpg",
    "https://i.ibb.co/kg5GTyF/TIM07916.jpg",
    "https://i.ibb.co/55GMxr8/TIM07918.jpg",

    "https://i.ibb.co/NV5d0wB/DSCF0434.jpg",
    "https://i.ibb.co/G5K0NYg/TIM07924.jpg",
    "https://i.ibb.co/Pz8FWqW/DSCF0443.jpg",
    "https://i.ibb.co/XDGFcQJ/TIM07927.jpg",
    "https://i.ibb.co/DDqCDD1/DSCF0439.jpg",
    "https://i.ibb.co/3vsfFtG/PSL21552.jpg",

    "https://i.ibb.co/jVCrK6Q/DSCF0445.jpg",
    "https://i.ibb.co/6Y53sQb/DSCF0447.jpg",
    "https://i.ibb.co/VWLfkkg/DSCF0448.jpg",
    "https://i.ibb.co/LC7ZpK8/DSCF0449.jpg",
    "https://i.ibb.co/HCGRLk9/DSCF0451.jpg",
    "https://i.ibb.co/pz3TB19/DSCF0453.jpg",

    "https://i.ibb.co/hVtH3Kz/IMG-5111.jpg",
    "https://i.ibb.co/C1h4w0f/IMG-5117.jpg",
    "https://i.ibb.co/Kz1zsmm/IMG-5118.jpg",
    "https://i.ibb.co/SJhn3Bh/IMG-5121.jpg",
    "https://i.ibb.co/zNPVKFK/IMG-5132.jpg",
    "https://i.ibb.co/KXbL3Cc/IMG-5130.jpg",
    "https://i.ibb.co/109mScS/IMG-5136.jpg",
    "https://i.ibb.co/5R3MxN9/PSL21551.jpg",
    "https://i.ibb.co/rZr52sH/PSL21553.jpg",
    "https://i.ibb.co/YRLnb7c/PSL21556.jpg",
    "https://i.ibb.co/F6xmgmd/PSL21558.jpg",
    "https://i.ibb.co/RhW258R/PSL21563.jpg",
    "https://i.ibb.co/8NxXX7d/PSL21561.jpg",
    "https://i.ibb.co/jZZjkCp/PSL21565.jpg",

    "https://i.ibb.co/FH6d1Z6/TIM07929.jpg",
    "https://i.ibb.co/zs3WP7C/TIM07933.jpg",
    "https://i.ibb.co/pyMVj4D/TIM07946.jpg",
    "https://i.ibb.co/qnsXgtx/TIM07956.jpg",
    // "https://i.ibb.co/WBzFwRQ/TIM07963.jpg",
  ],

  thumbnails: [
    "https://i.ibb.co/d7hbv4K/TIM07864.jpg",
    "https://i.ibb.co/2v2Tdd2/IMG-4612.jpg",
    "https://i.ibb.co/syfLV8M/TIM07867.jpg",
    "https://i.ibb.co/xDcLPPp/PSL21126.jpg",
    "https://i.ibb.co/qMgDnx5/DSCF0012.jpg",
    "https://i.ibb.co/wLPRG9L/PSL21190.jpg",
    "https://i.ibb.co/xg3rLJ0/PSL21188.jpg",
    "https://i.ibb.co/hMn8SNf/PSL21187.jpg",
    "https://i.ibb.co/xC89TKW/PSL21185.jpg",
    "https://i.ibb.co/PYWm6q4/PSL21194.jpg",
    "https://i.ibb.co/y4Btp8z/PSL21199.jpg",
    "https://i.ibb.co/3SVsMzT/IMG-4668.jpg",
    "https://i.ibb.co/g3BZX65/IMG-4666.jpg",
    "https://i.ibb.co/4SS9vrz/IMG-4723.jpg",
    "https://i.ibb.co/yfL3yMJ/DSCF0156.jpg",
    "https://i.ibb.co/9yb8dhR/PSL21476.jpg",
    "https://i.ibb.co/JQxNSG0/IMG-5097.jpg",
    "https://i.ibb.co/w4Btwq1/IMG-5095.jpg",
    "https://i.ibb.co/51wVCh1/IMG-5096.jpg",
    "https://i.ibb.co/jh6vTSt/TIM07908.jpg",
    "https://i.ibb.co/fXkXGd6/TIM07915.jpg",
    "https://i.ibb.co/0yRgBcB/TIM07910.jpg",
    "https://i.ibb.co/6ZzbBXP/TIM07911.jpg",
    "https://i.ibb.co/mHbDF9q/TIM07918.jpg",
    "https://i.ibb.co/1f70Nnx/TIM07916.jpg",
    "https://i.ibb.co/3CD7Q6t/TIM07924.jpg",
    "https://i.ibb.co/w0VFnDm/DSCF0434.jpg",
    "https://i.ibb.co/zHwQ2q2/DSCF0443.jpg",
    "https://i.ibb.co/ScZVFjv/TIM07927.jpg",
    "https://i.ibb.co/5W78WW1/DSCF0439.jpg",
    "https://i.ibb.co/sCP51Lk/PSL21552.jpg",
    "https://i.ibb.co/JnPfkZ2/DSCF0447.jpg",
    "https://i.ibb.co/B4hCDzQ/DSCF0445.jpg",
    "https://i.ibb.co/rGQDXXx/DSCF0448.jpg",
    "https://i.ibb.co/7Gm1yhX/DSCF0449.jpg",
    "https://i.ibb.co/YXfJ4qV/DSCF0451.jpg",
    "https://i.ibb.co/R0CKkgV/DSCF0453.jpg",
    "https://i.ibb.co/BBSj0gM/IMG-5111.jpg",
    "https://i.ibb.co/ZJQfzWq/IMG-5113.jpg",
    "https://i.ibb.co/98sBpY7/IMG-5117.jpg",
    "https://i.ibb.co/cXmX8xx/IMG-5118.jpg",
    "https://i.ibb.co/N78jyK8/IMG-5121.jpg",
    "https://i.ibb.co/pxbR9C6/IMG-5130.jpg",
    "https://i.ibb.co/TrPMz1z/IMG-5132.jpg",
    "https://i.ibb.co/VLvqK6K/IMG-5136.jpg",
    "https://i.ibb.co/CHy1skt/PSL21551.jpg",
    "https://i.ibb.co/y8jgFSq/PSL21553.jpg",
    "https://i.ibb.co/jh6bgbj/PSL21558.jpg",
    "https://i.ibb.co/TvgjrB4/PSL21556.jpg",
    "https://i.ibb.co/JmpCCsy/PSL21561.jpg",
    "https://i.ibb.co/ZHCdZ9q/PSL21563.jpg",
    "https://i.ibb.co/R44JTX5/PSL21565.jpg",
    "https://i.ibb.co/gy0btPQ/TIM07933.jpg",
    "https://i.ibb.co/0sqzL4q/TIM07929.jpg",
    "https://i.ibb.co/C2YCKVd/TIM07946.jpg",
    "https://i.ibb.co/X4sN5hV/TIM07956.jpg",
    "https://i.ibb.co/c28tHsP/TIM07963.jpg",
  ],
};

export default album;
