import React from "react";
import { useTranslation } from "react-i18next";

import GreenFlowerImg from "../../images/pink_flower.png";
import "./Welcome.scss";

function Welcome() {
  const { t } = useTranslation();

  return (
    <section id="welcome" className="welcome">
      <img className="img" src={GreenFlowerImg} alt="green_flower" />
      <div className="welcome-heading">{t("welcome.title")}</div>
      <div className="welcome-desc">{t("welcome.desc")}</div>
    </section>
  );
}

export default Welcome;
