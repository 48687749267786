const album = {
  title: "album1",
  images: [
    "https://i.ibb.co/vYHQM91/AMV-2298-min.jpg",
    "https://i.ibb.co/TTw9WjJ/AMV-2293-min.jpg",
    "https://i.ibb.co/kDt4JcT/AMV-2308-min.jpg",
    "https://i.ibb.co/L5r266B/AMV-2310-min.jpg",
    "https://i.ibb.co/0rMFmWp/AMV-2349-min.jpg",
    "https://i.ibb.co/ZftnW1N/AMV-2354-min.jpg",
    "https://i.ibb.co/RPz5BTT/AMV-2413-min.jpg",
    "https://i.ibb.co/YhtW45S/AMV-2432-min.jpg",
    "https://i.ibb.co/qLGz9MX/AMV-2494-min.jpg",
    "https://i.ibb.co/n14DBLN/AMV-2505-min.jpg",
    "https://i.ibb.co/9rdXhmQ/AMV-2576-min.jpg",
    "https://i.ibb.co/hWD36vJ/AMV-2619-min.jpg",
    "https://i.ibb.co/Tvp773w/AMV-2630-min.jpg",
    "https://i.ibb.co/TLs0DKY/AMV-2638-min.jpg",
    "https://i.ibb.co/VTC141h/AMV-2740-min.jpg",
    "https://i.ibb.co/x7F4Mkg/AMV-2727-min.jpg",
    "https://i.ibb.co/PMqfq4p/AMV-2651-min.jpg",
  ],
  thumbnails: [
    "https://i.ibb.co/7zSskMB/AMV-2293-min.jpg",
    "https://i.ibb.co/gV3PcYy/AMV-2298-min.jpg",
    "https://i.ibb.co/hdSBc8N/AMV-2308-min.jpg",
    "https://i.ibb.co/xHYRGGw/AMV-2310-min.jpg",
    "https://i.ibb.co/YhkyPK4/AMV-2349-min.jpg",
    "https://i.ibb.co/9pzFs3G/AMV-2354-min.jpg",
    "https://i.ibb.co/Dk5q8ff/AMV-2413-min.jpg",
    "https://i.ibb.co/SBKxDkg/AMV-2432-min.jpg",
    "https://i.ibb.co/cK5PtcM/AMV-2494-min.jpg",
    "https://i.ibb.co/bvYrFKM/AMV-2505-min.jpg",
    "https://i.ibb.co/XCGBDfc/AMV-2576-min.jpg",
    "https://i.ibb.co/DC50JnH/AMV-2619-min.jpg",
    "https://i.ibb.co/1vhPPW0/AMV-2630-min.jpg",
    "https://i.ibb.co/DpZMPGz/AMV-2638-min.jpg",
    "https://i.ibb.co/bFc8cLh/AMV-2651-min.jpg",
    "https://i.ibb.co/mcNxHjy/AMV-2727-min.jpg",
    "https://i.ibb.co/ZxgjDjw/AMV-2740-min.jpg",
  ],
};

export default album;
