import React from "react";
import Slider from "./Slider/Slider";

import "./Header.scss";

const Header = () => {
  return (
    <header id="home">
      <Slider />
    </header>
  );
};

export default Header;
