const album = {
  title: "album3",
  images: [
    "https://i.ibb.co/r7YKtJB/AMV-2926-min.jpg",
    "https://i.ibb.co/mGkLMWP/AMV-2964-min.jpg",
    "https://i.ibb.co/jLJbsxC/AMV-3005-min.jpg",
    "https://i.ibb.co/myrSKHS/AMV-3080-min.jpg",
    "https://i.ibb.co/T4q36qB/AMV-3128-min.jpg",
    "https://i.ibb.co/n6Z7TDd/AMV-3139-min.jpg",
    "https://i.ibb.co/gPJQKPJ/AMV-3199-min.jpg",
    "https://i.ibb.co/qRG0GbP/AMV-3225-min.jpg",
    "https://i.ibb.co/3vQh7SD/AMV-3387-min.jpg",
    "https://i.ibb.co/NT5TFQd/AMV-3435-min.jpg",
    "https://i.ibb.co/b2bJPmM/AMV-3492-min.jpg",
    "https://i.ibb.co/T8DgBkZ/AMV-3584-min.jpg",
    "https://i.ibb.co/J5HJj9D/AMV-3589-min.jpg",
    "https://i.ibb.co/71p6JFc/AMV-3619-min.jpg",
    "https://i.ibb.co/94VYqKf/AMV-3649-min.jpg",
    "https://i.ibb.co/Vq6k3F0/AMV-3707-min.jpg",
    "https://i.ibb.co/h9Ls4h9/AMV-3729-min.jpg",
    "https://i.ibb.co/X77nqDG/AMV-3773-min.jpg",
    "https://i.ibb.co/xMpKSCG/AMV-3806-min.jpg",
    "https://i.ibb.co/HxWLd1j/AMV-3837-min.jpg",
    "https://i.ibb.co/6gTyTNm/AMV-3935-min.jpg",
    "https://i.ibb.co/qx0QZb0/AMV-4042-min.jpg",
    "https://i.ibb.co/fH4BzCr/AMV-4016-min.jpg",
  ],
  thumbnails: [
    "https://i.ibb.co/56mNfHJ/AMV-2964-min.jpg",
    "https://i.ibb.co/986mvjx/AMV-2926-min.jpg",
    "https://i.ibb.co/qRJ7V4H/AMV-3005-min.jpg",
    "https://i.ibb.co/pK6nT3n/AMV-3080-min.jpg",
    "https://i.ibb.co/ByKMbKV/AMV-3128-min.jpg",
    "https://i.ibb.co/S5Wt2KY/AMV-3139-min.jpg",
    "https://i.ibb.co/F0zcT0z/AMV-3199-min.jpg",
    "https://i.ibb.co/19VsVHw/AMV-3225-min.jpg",
    "https://i.ibb.co/9hSYNyF/AMV-3387-min.jpg",
    "https://i.ibb.co/nP9PwZN/AMV-3435-min.jpg",
    "https://i.ibb.co/vQXYHq6/AMV-3492-min.jpg",
    "https://i.ibb.co/Yk4L736/AMV-3584-min.jpg",
    "https://i.ibb.co/gzJKZxk/AMV-3589-min.jpg",
    "https://i.ibb.co/rwp1GJP/AMV-3619-min.jpg",
    "https://i.ibb.co/t2xCbjN/AMV-3649-min.jpg",
    "https://i.ibb.co/2kJp5z0/AMV-3707-min.jpg",
    "https://i.ibb.co/LRYScbR/AMV-3729-min.jpg",
    "https://i.ibb.co/2vvzTSp/AMV-3773-min.jpg",
    "https://i.ibb.co/brSjgWL/AMV-3806-min.jpg",
    "https://i.ibb.co/RBxGyZq/AMV-3837-min.jpg",
    "https://i.ibb.co/gzfrf63/AMV-3935-min.jpg",
    "https://i.ibb.co/0rh8RfY/AMV-4016-min.jpg",
    "https://i.ibb.co/HXN8JmN/AMV-4042-min.jpg",
  ],
};

export default album;
