const album = {
  title: "theVow",
  images: [
    "https://i.ibb.co/zVkHPT6/DSCF0643.jpg",
    "https://i.ibb.co/RDrzJRp/PSL21845.jpg",
    "https://i.ibb.co/pXtLk6R/PSL21858.jpg",
    "https://i.ibb.co/phkfMcT/PSL21847.jpg",
    "https://i.ibb.co/BKtFXyj/IMG-5396.jpg",
    "https://i.ibb.co/Mf4bc7c/DSCF0646.jpg",
    "https://i.ibb.co/6r2fv8f/IMG-5386.jpg",
    "https://i.ibb.co/phJj305/IMG-5388.jpg",
    "https://i.ibb.co/0cDrNfQ/PSL21852.jpg",
    "https://i.ibb.co/Ydttmfc/DSCF0650.jpg",
    "https://i.ibb.co/F87MSxP/DSCF0652.jpg",
    "https://i.ibb.co/RzhCJLB/IMG-5400.jpg",
    "https://i.ibb.co/6W4D0C4/DSCF0653.jpg",
    "https://i.ibb.co/VxLC0Lm/DSCF0659.jpg",
    "https://i.ibb.co/JKx7vFc/DSCF0665.jpg",
    "https://i.ibb.co/ZKgstgC/DSCF0672.jpg",
    "https://i.ibb.co/M9hDRN5/DSCF0676.jpg",
    "https://i.ibb.co/cJvc4WQ/IMG-5405.jpg",
    "https://i.ibb.co/18f1qfm/IMG-5411.jpg",
    "https://i.ibb.co/HTrRRKV/IMG-5413.jpg",
    "https://i.ibb.co/6rWnj6N/IMG-5415.jpg",
    "https://i.ibb.co/mSfwjDP/DSCF0678.jpg",
    "https://i.ibb.co/2jrhKFz/DSCF0680.jpg",
    "https://i.ibb.co/ZcVzLZ7/IMG-5418.jpg",
    "https://i.ibb.co/1G3gNDv/IMG-5420.jpg",
    "https://i.ibb.co/Q67X0FT/IMG-5425.jpg",
    "https://i.ibb.co/s36PKNy/IMG-5426.jpg",
    "https://i.ibb.co/D9yrrRy/PSL21868.jpg",
    "https://i.ibb.co/w0MHNhJ/PSL21871.jpg",
    "https://i.ibb.co/8dqJxp8/IMG-5431.jpg",
    "https://i.ibb.co/Tm2PtBr/IMG-5427.jpg",
    "https://i.ibb.co/6vTTsQT/DSCF0691.jpg",
    "https://i.ibb.co/Q6ZRJpT/PSL21879.jpg",
    "https://i.ibb.co/G3prxZx/PSL21888.jpg",
    "https://i.ibb.co/gP3Zn22/PSL21897.jpg",
    "https://i.ibb.co/fxqzSnq/PSL21900.jpg",
    "https://i.ibb.co/qRTTM4f/IMG-5449.jpg",
    "https://i.ibb.co/9WGRWWz/IMG-5450.jpg",
    "https://i.ibb.co/GMvGdn4/IMG-5459.jpg",
    "https://i.ibb.co/tY7kTcm/IMG-5468.jpg",
    "https://i.ibb.co/yRP7bcb/DSCF0695.jpg",
    "https://i.ibb.co/TY7VY5N/DSCF0697.jpg",
    "https://i.ibb.co/jHngyYW/DSCF0700.jpg",
    "https://i.ibb.co/1fpfkn3/IMG-5470.jpg",
    "https://i.ibb.co/61r5YXS/PSL21910.jpg",
    "https://i.ibb.co/W01PT71/PSL21915.jpg",
    "https://i.ibb.co/BZL7Wnd/PSL21920.jpg",
    "https://i.ibb.co/DDJQHqW/IMG-5474.jpg",
    "https://i.ibb.co/RjsWGV4/IMG-5479.jpg",
    "https://i.ibb.co/ZxSGVgQ/IMG-5483.jpg",
    "https://i.ibb.co/5MWpF4N/PSL21928.jpg",
    "https://i.ibb.co/x2xVRX4/PSL21932.jpg",
    "https://i.ibb.co/cD0qVwS/PSL21933.jpg",
    "https://i.ibb.co/k0WHXXr/PSL21935.jpg",
    "https://i.ibb.co/m9GJLVF/PSL21942.jpg",
    "https://i.ibb.co/q7KJ1HG/IMG-5496.jpg",
    "https://i.ibb.co/q0z860X/IMG-5500.jpg",
    "https://i.ibb.co/d57J8F6/IMG-5508.jpg",
    "https://i.ibb.co/wBwwGc8/IMG-5512.jpg",
    "https://i.ibb.co/JvD4pnm/PSL21951.jpg",
    "https://i.ibb.co/Kwbf0mS/PSL21961.jpg",
    "https://i.ibb.co/Z1NDwy6/PSL21970.jpg",
    "https://i.ibb.co/dBXvPLD/PSL21976.jpg",
    "https://i.ibb.co/b1tw57q/PSL21977.jpg",
    "https://i.ibb.co/3YpX3Gw/PSL21984.jpg",
    "https://i.ibb.co/0f2T985/PSL21989.jpg",
    "https://i.ibb.co/VHhwHPG/PSL21992.jpg",
    "https://i.ibb.co/bNBj0VV/PSL21998.jpg",
    "https://i.ibb.co/dkZ31LK/PSL22003.jpg",
    "https://i.ibb.co/gw5GnvX/IMG-5522.jpg",
    "https://i.ibb.co/NC8M1Hw/PSL22006.jpg",
    "https://i.ibb.co/f0xHWGr/PSL22008.jpg",
    "https://i.ibb.co/p3bFbQw/PSL22005.jpg",
    "https://i.ibb.co/C8VVg8L/IMG-5533.jpg",
    "https://i.ibb.co/QQZktpp/IMG-5544.jpg",
    "https://i.ibb.co/SRBgwkc/IMG-5547.jpg",
    "https://i.ibb.co/P6gxXcp/PSL22028.jpg",
    "https://i.ibb.co/3Cb6ND8/PSL22032.jpg",
    "https://i.ibb.co/ThTwJHt/PSL22036.jpg",
    "https://i.ibb.co/F5g7dW9/PSL22039.jpg",
    "https://i.ibb.co/MpFBdBH/PSL22041.jpg",
    "https://i.ibb.co/Q8rnT5T/IMG-5553.jpg",
    "https://i.ibb.co/8KD1k3t/DSCF0708.jpg",
    "https://i.ibb.co/kHk8bxm/DSCF0710.jpg",
    "https://i.ibb.co/fdjX0Dh/DSCF0718.jpg",
    "https://i.ibb.co/QN4ZjDC/DSCF0730.jpg",
    "https://i.ibb.co/N2t9T3Y/DSCF0735.jpg",
    "https://i.ibb.co/vXNsFg8/DSCF0742.jpg",
    "https://i.ibb.co/N2HWS4t/DSCF0748.jpg",
    "https://i.ibb.co/SBXwB72/DSCF0755.jpg",
    "https://i.ibb.co/7rx1S9w/DSCF0760.jpg",
    "https://i.ibb.co/pQFqbwp/DSCF0763.jpg",
    "https://i.ibb.co/7gZSTmM/IMG-5565.jpg",
    "https://i.ibb.co/pRpVKHx/IMG-5569.jpg",
    "https://i.ibb.co/yXPLPt7/DSCF0765.jpg",
    "https://i.ibb.co/VVQ8JKL/DSCF0770.jpg",
    "https://i.ibb.co/jkMbbGZ/DSCF0776.jpg",
    "https://i.ibb.co/qF9sK9g/DSCF0787.jpg",
    "https://i.ibb.co/WK26tj2/DSCF0789.jpg",
    "https://i.ibb.co/gj9XQV4/PSL22048.jpg",
    "https://i.ibb.co/xC4j5mL/PSL22052.jpg",
    "https://i.ibb.co/p3ZSGGZ/DSCF0795.jpg",
    "https://i.ibb.co/6XdPSf5/IMG-5596.jpg",
    "https://i.ibb.co/B4vRxGp/PSL22054.jpg",
    "https://i.ibb.co/R9D8hRm/PSL22060.jpg",
    "https://i.ibb.co/ZhM97S1/PSL22068.jpg",
    "https://i.ibb.co/PTKmm2W/IMG-5608.jpg",
    "https://i.ibb.co/k8hPy98/IMG-5614.jpg",
    "https://i.ibb.co/znWdrtm/TIM07967.jpg",
    "https://i.ibb.co/dmwrrcJ/IMG-5622.jpg",
    "https://i.ibb.co/59HWt3d/IMG-5627.jpg",
    "https://i.ibb.co/b6LcHKP/PSL22072.jpg",
    "https://i.ibb.co/M6cmH3r/PSL22079.jpg",
    "https://i.ibb.co/nrNmBPt/DSCF0810.jpg",
    "https://i.ibb.co/s9b8Jxx/DSCF0814.jpg",
    "https://i.ibb.co/k8NY8mw/DSCF0825.jpg",
    "https://i.ibb.co/zVTxtLk/DSCF0828.jpg",
    "https://i.ibb.co/jMs0Lst/PSL22081.jpg",
    "https://i.ibb.co/NLMXtCw/IMG-5642.jpg",
    "https://i.ibb.co/gt7JfLt/IMG-5645.jpg",
    "https://i.ibb.co/WFtGMKw/IMG-5653.jpg",
    "https://i.ibb.co/KhwbGnz/IMG-5655.jpg",
    "https://i.ibb.co/ggBTnYW/IMG-5657.jpg",
    "https://i.ibb.co/68QGmVp/IMG-5661.jpg",
    "https://i.ibb.co/rkZDNVr/IMG-5677.jpg",
    "https://i.ibb.co/3fGz9yc/IMG-5663.jpg",
    "https://i.ibb.co/jfT6H8t/DSCF0845.jpg",
    "https://i.ibb.co/09yvm2S/DSCF0847.jpg",
    "https://i.ibb.co/M6MSgyf/DSCF0854.jpg",
    "https://i.ibb.co/yBM2y6R/PSL22094.jpg",
    "https://i.ibb.co/dLVqWFg/PSL22099.jpg",
    "https://i.ibb.co/1s1nkdz/PSL22106.jpg",
    "https://i.ibb.co/z452x97/PSL22118.jpg",
    "https://i.ibb.co/WsF0zyr/PSL22121.jpg",
    "https://i.ibb.co/8d9M06z/PSL22122.jpg",
    "https://i.ibb.co/Bzpg762/PSL22127.jpg",
    "https://i.ibb.co/kS8NTDY/PSL22130.jpg",
    "https://i.ibb.co/b38JXG6/PSL22131.jpg",
    "https://i.ibb.co/FJMVFcz/PSL22134.jpg",
    "https://i.ibb.co/XXjHDTW/PSL22139.jpg",
    "https://i.ibb.co/mX1BLtj/PSL22143.jpg",
    "https://i.ibb.co/c8Njyy2/TIM07980.jpg",
    "https://i.ibb.co/hYVNLcQ/IMG-5699.jpg",
    "https://i.ibb.co/k0SDhYQ/DSCF0874.jpg",
    "https://i.ibb.co/rkRTVqc/DSCF0876.jpg",
    "https://i.ibb.co/59Wb8sc/DSCF0885.jpg",
    "https://i.ibb.co/fMgr7Ps/DSCF0896.jpg",
    "https://i.ibb.co/4gsHDRL/DSCF0941.jpg",
    "https://i.ibb.co/DgZXVxq/DSCF0942.jpg",
    "https://i.ibb.co/gjC5kGJ/TIM07972.jpg",
    "https://i.ibb.co/bWPJvwr/DSCF0948.jpg",
    "https://i.ibb.co/5BGj027/DSCF0906.jpg",
    "https://i.ibb.co/kDScT8M/DSCF0911.jpg",
    "https://i.ibb.co/9s349zv/PSL22112.jpg",
  ],
  thumbnails: [
    "https://i.ibb.co/zVkHPT6/DSCF0643.jpg",
    "https://i.ibb.co/RDrzJRp/PSL21845.jpg",
    "https://i.ibb.co/pXtLk6R/PSL21858.jpg",
    "https://i.ibb.co/phkfMcT/PSL21847.jpg",
    "https://i.ibb.co/Mf4bc7c/DSCF0646.jpg",
    "https://i.ibb.co/6r2fv8f/IMG-5386.jpg",
    "https://i.ibb.co/phJj305/IMG-5388.jpg",
    "https://i.ibb.co/BKtFXyj/IMG-5396.jpg",
    "https://i.ibb.co/0cDrNfQ/PSL21852.jpg",
    "https://i.ibb.co/Ydttmfc/DSCF0650.jpg",
    "https://i.ibb.co/F87MSxP/DSCF0652.jpg",
    "https://i.ibb.co/RzhCJLB/IMG-5400.jpg",
    "https://i.ibb.co/6W4D0C4/DSCF0653.jpg",
    "https://i.ibb.co/VxLC0Lm/DSCF0659.jpg",
    "https://i.ibb.co/JKx7vFc/DSCF0665.jpg",
    "https://i.ibb.co/ZKgstgC/DSCF0672.jpg",
    "https://i.ibb.co/M9hDRN5/DSCF0676.jpg",
    "https://i.ibb.co/cJvc4WQ/IMG-5405.jpg",
    "https://i.ibb.co/18f1qfm/IMG-5411.jpg",
    "https://i.ibb.co/HTrRRKV/IMG-5413.jpg",
    "https://i.ibb.co/6rWnj6N/IMG-5415.jpg",
    "https://i.ibb.co/mSfwjDP/DSCF0678.jpg",
    "https://i.ibb.co/2jrhKFz/DSCF0680.jpg",
    "https://i.ibb.co/ZcVzLZ7/IMG-5418.jpg",
    "https://i.ibb.co/1G3gNDv/IMG-5420.jpg",
    "https://i.ibb.co/Q67X0FT/IMG-5425.jpg",
    "https://i.ibb.co/s36PKNy/IMG-5426.jpg",
    "https://i.ibb.co/D9yrrRy/PSL21868.jpg",
    "https://i.ibb.co/w0MHNhJ/PSL21871.jpg",
    "https://i.ibb.co/8dqJxp8/IMG-5431.jpg",
    "https://i.ibb.co/Tm2PtBr/IMG-5427.jpg",
    "https://i.ibb.co/6vTTsQT/DSCF0691.jpg",
    "https://i.ibb.co/Q6ZRJpT/PSL21879.jpg",
    "https://i.ibb.co/G3prxZx/PSL21888.jpg",
    "https://i.ibb.co/gP3Zn22/PSL21897.jpg",
    "https://i.ibb.co/fxqzSnq/PSL21900.jpg",
    "https://i.ibb.co/qRTTM4f/IMG-5449.jpg",
    "https://i.ibb.co/9WGRWWz/IMG-5450.jpg",
    "https://i.ibb.co/GMvGdn4/IMG-5459.jpg",
    "https://i.ibb.co/tY7kTcm/IMG-5468.jpg",
    "https://i.ibb.co/yRP7bcb/DSCF0695.jpg",
    "https://i.ibb.co/TY7VY5N/DSCF0697.jpg",
    "https://i.ibb.co/jHngyYW/DSCF0700.jpg",
    "https://i.ibb.co/1fpfkn3/IMG-5470.jpg",
    "https://i.ibb.co/61r5YXS/PSL21910.jpg",
    "https://i.ibb.co/W01PT71/PSL21915.jpg",
    "https://i.ibb.co/BZL7Wnd/PSL21920.jpg",
    "https://i.ibb.co/DDJQHqW/IMG-5474.jpg",
    "https://i.ibb.co/RjsWGV4/IMG-5479.jpg",
    "https://i.ibb.co/ZxSGVgQ/IMG-5483.jpg",
    "https://i.ibb.co/5MWpF4N/PSL21928.jpg",
    "https://i.ibb.co/x2xVRX4/PSL21932.jpg",
    "https://i.ibb.co/cD0qVwS/PSL21933.jpg",
    "https://i.ibb.co/k0WHXXr/PSL21935.jpg",
    "https://i.ibb.co/m9GJLVF/PSL21942.jpg",
    "https://i.ibb.co/q7KJ1HG/IMG-5496.jpg",
    "https://i.ibb.co/q0z860X/IMG-5500.jpg",
    "https://i.ibb.co/d57J8F6/IMG-5508.jpg",
    "https://i.ibb.co/wBwwGc8/IMG-5512.jpg",
    "https://i.ibb.co/JvD4pnm/PSL21951.jpg",
    "https://i.ibb.co/Kwbf0mS/PSL21961.jpg",
    "https://i.ibb.co/Z1NDwy6/PSL21970.jpg",
    "https://i.ibb.co/dBXvPLD/PSL21976.jpg",
    "https://i.ibb.co/b1tw57q/PSL21977.jpg",
    "https://i.ibb.co/3YpX3Gw/PSL21984.jpg",
    "https://i.ibb.co/0f2T985/PSL21989.jpg",
    "https://i.ibb.co/VHhwHPG/PSL21992.jpg",
    "https://i.ibb.co/bNBj0VV/PSL21998.jpg",
    "https://i.ibb.co/dkZ31LK/PSL22003.jpg",
    "https://i.ibb.co/gw5GnvX/IMG-5522.jpg",
    "https://i.ibb.co/NC8M1Hw/PSL22006.jpg",
    "https://i.ibb.co/f0xHWGr/PSL22008.jpg",
    "https://i.ibb.co/p3bFbQw/PSL22005.jpg",
    "https://i.ibb.co/C8VVg8L/IMG-5533.jpg",
    "https://i.ibb.co/QQZktpp/IMG-5544.jpg",
    "https://i.ibb.co/SRBgwkc/IMG-5547.jpg",
    "https://i.ibb.co/P6gxXcp/PSL22028.jpg",
    "https://i.ibb.co/3Cb6ND8/PSL22032.jpg",
    "https://i.ibb.co/ThTwJHt/PSL22036.jpg",
    "https://i.ibb.co/F5g7dW9/PSL22039.jpg",
    "https://i.ibb.co/MpFBdBH/PSL22041.jpg",
    "https://i.ibb.co/Q8rnT5T/IMG-5553.jpg",
    "https://i.ibb.co/8KD1k3t/DSCF0708.jpg",
    "https://i.ibb.co/kHk8bxm/DSCF0710.jpg",
    "https://i.ibb.co/fdjX0Dh/DSCF0718.jpg",
    "https://i.ibb.co/QN4ZjDC/DSCF0730.jpg",
    "https://i.ibb.co/N2t9T3Y/DSCF0735.jpg",
    "https://i.ibb.co/vXNsFg8/DSCF0742.jpg",
    "https://i.ibb.co/N2HWS4t/DSCF0748.jpg",
    "https://i.ibb.co/SBXwB72/DSCF0755.jpg",
    "https://i.ibb.co/7rx1S9w/DSCF0760.jpg",
    "https://i.ibb.co/pQFqbwp/DSCF0763.jpg",
    "https://i.ibb.co/7gZSTmM/IMG-5565.jpg",
    "https://i.ibb.co/pRpVKHx/IMG-5569.jpg",
    "https://i.ibb.co/yXPLPt7/DSCF0765.jpg",
    "https://i.ibb.co/VVQ8JKL/DSCF0770.jpg",
    "https://i.ibb.co/jkMbbGZ/DSCF0776.jpg",
    "https://i.ibb.co/qF9sK9g/DSCF0787.jpg",
    "https://i.ibb.co/WK26tj2/DSCF0789.jpg",
    "https://i.ibb.co/gj9XQV4/PSL22048.jpg",
    "https://i.ibb.co/xC4j5mL/PSL22052.jpg",
    "https://i.ibb.co/p3ZSGGZ/DSCF0795.jpg",
    "https://i.ibb.co/6XdPSf5/IMG-5596.jpg",
    "https://i.ibb.co/B4vRxGp/PSL22054.jpg",
    "https://i.ibb.co/R9D8hRm/PSL22060.jpg",
    "https://i.ibb.co/ZhM97S1/PSL22068.jpg",
    "https://i.ibb.co/PTKmm2W/IMG-5608.jpg",
    "https://i.ibb.co/k8hPy98/IMG-5614.jpg",
    "https://i.ibb.co/znWdrtm/TIM07967.jpg",
    "https://i.ibb.co/dmwrrcJ/IMG-5622.jpg",
    "https://i.ibb.co/59HWt3d/IMG-5627.jpg",
    "https://i.ibb.co/b6LcHKP/PSL22072.jpg",
    "https://i.ibb.co/M6cmH3r/PSL22079.jpg",
    "https://i.ibb.co/nrNmBPt/DSCF0810.jpg",
    "https://i.ibb.co/s9b8Jxx/DSCF0814.jpg",
    "https://i.ibb.co/k8NY8mw/DSCF0825.jpg",
    "https://i.ibb.co/zVTxtLk/DSCF0828.jpg",
    "https://i.ibb.co/jMs0Lst/PSL22081.jpg",
    "https://i.ibb.co/NLMXtCw/IMG-5642.jpg",
    "https://i.ibb.co/gt7JfLt/IMG-5645.jpg",
    "https://i.ibb.co/WFtGMKw/IMG-5653.jpg",
    "https://i.ibb.co/KhwbGnz/IMG-5655.jpg",
    "https://i.ibb.co/ggBTnYW/IMG-5657.jpg",
    "https://i.ibb.co/68QGmVp/IMG-5661.jpg",
    "https://i.ibb.co/rkZDNVr/IMG-5677.jpg",
    "https://i.ibb.co/3fGz9yc/IMG-5663.jpg",
    "https://i.ibb.co/jfT6H8t/DSCF0845.jpg",
    "https://i.ibb.co/09yvm2S/DSCF0847.jpg",
    "https://i.ibb.co/M6MSgyf/DSCF0854.jpg",
    "https://i.ibb.co/yBM2y6R/PSL22094.jpg",
    "https://i.ibb.co/dLVqWFg/PSL22099.jpg",
    "https://i.ibb.co/1s1nkdz/PSL22106.jpg",
    "https://i.ibb.co/9s349zv/PSL22112.jpg",
    "https://i.ibb.co/z452x97/PSL22118.jpg",
    "https://i.ibb.co/WsF0zyr/PSL22121.jpg",
    "https://i.ibb.co/8d9M06z/PSL22122.jpg",
    "https://i.ibb.co/Bzpg762/PSL22127.jpg",
    "https://i.ibb.co/kS8NTDY/PSL22130.jpg",
    "https://i.ibb.co/b38JXG6/PSL22131.jpg",
    "https://i.ibb.co/FJMVFcz/PSL22134.jpg",
    "https://i.ibb.co/XXjHDTW/PSL22139.jpg",
    "https://i.ibb.co/c8Njyy2/TIM07980.jpg",
    "https://i.ibb.co/mX1BLtj/PSL22143.jpg",
    "https://i.ibb.co/hYVNLcQ/IMG-5699.jpg",
    "https://i.ibb.co/k0SDhYQ/DSCF0874.jpg",
    "https://i.ibb.co/rkRTVqc/DSCF0876.jpg",
    "https://i.ibb.co/59Wb8sc/DSCF0885.jpg",
    "https://i.ibb.co/fMgr7Ps/DSCF0896.jpg",
    "https://i.ibb.co/4gsHDRL/DSCF0941.jpg",
    "https://i.ibb.co/DgZXVxq/DSCF0942.jpg",
    "https://i.ibb.co/gjC5kGJ/TIM07972.jpg",
    "https://i.ibb.co/bWPJvwr/DSCF0948.jpg",
    "https://i.ibb.co/5BGj027/DSCF0906.jpg",
    "https://i.ibb.co/kDScT8M/DSCF0911.jpg",
  ],
};

export default album;
