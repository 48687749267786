const album = {
  title: "traditionalDress",
  images: [
    "https://i.ibb.co/61zNpns/PSL21214.jpg",
    "https://i.ibb.co/SvzGZ4N/PSL21201.jpg",
    "https://i.ibb.co/gVRWjKV/PSL21220.jpg",
    "https://i.ibb.co/bsCZk8b/PSL21216.jpg",
    "https://i.ibb.co/BqJb36H/PSL21223.jpg",
    "https://i.ibb.co/DQtXrq0/PSL21225.jpg",
    "https://i.ibb.co/fCWBrzQ/PSL21235.jpg",
    "https://i.ibb.co/ss2K5RZ/PSL21238.jpg",
    "https://i.ibb.co/6XpvPgN/PSL21246.jpg",
    "https://i.ibb.co/Yhs0cqZ/PSL21249.jpg",
    "https://i.ibb.co/F7sZbh1/PSL21255.jpg",
    "https://i.ibb.co/nCtZ9pL/PSL21267.jpg",
    "https://i.ibb.co/D9HHQDP/PSL21275.jpg",
    "https://i.ibb.co/Zmzv4Qq/PSL21276.jpg",
    "https://i.ibb.co/RCGCyvp/PSL21280.jpg",
    "https://i.ibb.co/47gThJv/PSL21292.jpg",
    "https://i.ibb.co/kmZg0j3/PSL21294.jpg",
    "https://i.ibb.co/T1tbVh8/PSL21299.jpg",
    "https://i.ibb.co/QNHrvV4/PSL21313.jpg",
    "https://i.ibb.co/R62DSFd/PSL21322.jpg",
    "https://i.ibb.co/vQrZckG/IMG-4706.jpg",
    "https://i.ibb.co/hmrZ1X4/PSL21327.jpg",
    "https://i.ibb.co/YkxNs4X/PSL21329.jpg",
    "https://i.ibb.co/TrxfcXH/PSL21334.jpg",
    "https://i.ibb.co/fxrYpvR/PSL21342.jpg",
    "https://i.ibb.co/4WSsxhd/PSL21350.jpg",
    "https://i.ibb.co/n6FQfSW/PSL21380.jpg",
    "https://i.ibb.co/kx4r1d6/PSL21385.jpg",
    "https://i.ibb.co/gt5tKRG/PSL21390.jpg",
    "https://i.ibb.co/QjV2zPF/DSCF0034.jpg",
    "https://i.ibb.co/B3tyjjB/DSCF0043.jpg",
    "https://i.ibb.co/P6hqrbc/DSCF0045.jpg",
    "https://i.ibb.co/gtdTshy/DSCF0050.jpg",
    "https://i.ibb.co/6tXDH4Q/DSCF0052.jpg",
    "https://i.ibb.co/LhLW1v9/DSCF0056.jpg",
    "https://i.ibb.co/0yL6hv5/DSCF0062.jpg",
    "https://i.ibb.co/ScB5kyB/DSCF0064.jpg",
    "https://i.ibb.co/TrV1nr5/DSCF0069.jpg",
    "https://i.ibb.co/hy8tdPG/DSCF0076.jpg",
    "https://i.ibb.co/THYwYMw/DSCF0081.jpg",
    "https://i.ibb.co/sqRy20F/DSCF0083.jpg",
    "https://i.ibb.co/BcY1fMR/DSCF0090.jpg",
    "https://i.ibb.co/HhMqwLj/DSCF0098.jpg",
    "https://i.ibb.co/nPyrkW8/DSCF0093.jpg",
    "https://i.ibb.co/vBgzCyB/DSCF0100.jpg",
    "https://i.ibb.co/bvm9CvL/DSCF0104.jpg",
    "https://i.ibb.co/Ws6Jgsm/IMG-4854.jpg",
    "https://i.ibb.co/yncP67N/PSL21400.jpg",
    "https://i.ibb.co/1Tj2WRH/PSL21405.jpg",
    "https://i.ibb.co/89p7VKX/PSL21415.jpg",
    "https://i.ibb.co/Ypmgm5D/PSL21424.jpg",
    "https://i.ibb.co/qm7cc5d/PSL21429.jpg",
    "https://i.ibb.co/d0gmHwD/PSL21432.jpg",
    "https://i.ibb.co/X8jK4mh/PSL21442.jpg",
    "https://i.ibb.co/7rDCXjc/PSL21447.jpg",
    "https://i.ibb.co/jGm8sJD/PSL21449.jpg",
    "https://i.ibb.co/LSmPfMF/PSL21453.jpg",
    "https://i.ibb.co/BjwyzPq/PSL21458.jpg",
    "https://i.ibb.co/ZXNBw0J/PSL21459.jpg",
    "https://i.ibb.co/N7j8grn/DSCF0105.jpg",
    "https://i.ibb.co/pncDbNC/DSCF0151.jpg",
    "https://i.ibb.co/8g4yy9b/DSCF0107.jpg",
    "https://i.ibb.co/Nnt23KC/DSCF0114.jpg",
    "https://i.ibb.co/yWCbgDD/DSCF0120.jpg",
    "https://i.ibb.co/6HJvMcH/DSCF0123.jpg",
    "https://i.ibb.co/HBRb2TB/DSCF0125.jpg",
    "https://i.ibb.co/mH19pB2/DSCF0127.jpg",
    "https://i.ibb.co/fxf3byW/DSCF0130.jpg",
    "https://i.ibb.co/zZmxPxf/DSCF0140.jpg",
    "https://i.ibb.co/FJrXsx3/DSCF0143.jpg",
    "https://i.ibb.co/9NwSVsL/DSCF0147.jpg",
    "https://i.ibb.co/Jxc6k0s/IMG-4732.jpg",
    "https://i.ibb.co/7tzW35c/PSL21460.jpg",
    "https://i.ibb.co/nzX7cGq/PSL21463.jpg",
    "https://i.ibb.co/7KyXNzs/PSL21466.jpg",
    "https://i.ibb.co/PCK6jWm/PSL21471.jpg",
    "https://i.ibb.co/djRPy7k/IMG-4735.jpg",
    "https://i.ibb.co/0ny6dLx/IMG-4738.jpg",
    "https://i.ibb.co/3rd3G7g/IMG-4750.jpg",
    "https://i.ibb.co/hsScc3Z/IMG-4753.jpg",
    "https://i.ibb.co/g6wPnf5/IMG-4757.jpg",
    "https://i.ibb.co/TvyqLNw/IMG-4764.jpg",
    "https://i.ibb.co/pvRSwwP/IMG-4771.jpg",
    "https://i.ibb.co/SQ4ty5V/IMG-4778.jpg",
    "https://i.ibb.co/C7SLbYn/IMG-4782.jpg",
    "https://i.ibb.co/124zs1d/IMG-4787.jpg",
    "https://i.ibb.co/86gzGnt/IMG-4793.jpg",
    "https://i.ibb.co/ykYWPcf/IMG-4796.jpg",
    "https://i.ibb.co/FsnfVNd/IMG-4812.jpg",
    "https://i.ibb.co/JRF8h2r/IMG-4818.jpg",
    "https://i.ibb.co/mSc705J/IMG-4825.jpg",
    "https://i.ibb.co/hKvsy7n/IMG-4829.jpg",
    "https://i.ibb.co/7tRKPWw/IMG-4843.jpg",
    "https://i.ibb.co/VTMyn4J/IMG-4833.jpg",
    "https://i.ibb.co/ZxGtFNS/IMG-4836.jpg",
    "https://i.ibb.co/TrGH2DF/IMG-4835.jpg",
    "https://i.ibb.co/qrvj5hS/IMG-4838.jpg",
  ],

  thumbnails: [
    "https://i.ibb.co/jWmHx64/PSL21214.jpg",
    "https://i.ibb.co/ryVNXRk/PSL21201.jpg",
    "https://i.ibb.co/ySQkfjS/PSL21220.jpg",
    "https://i.ibb.co/Htbsjkh/PSL21216.jpg",
    "https://i.ibb.co/5B3m4jX/PSL21223.jpg",
    "https://i.ibb.co/qpdGJZT/PSL21225.jpg",
    "https://i.ibb.co/XzPNFrV/PSL21235.jpg",
    "https://i.ibb.co/fr2k8Fs/PSL21238.jpg",
    "https://i.ibb.co/bbyHBz7/PSL21246.jpg",
    "https://i.ibb.co/1TWzrw6/PSL21249.jpg",
    "https://i.ibb.co/VBHnWLG/PSL21255.jpg",
    "https://i.ibb.co/dgx1FZ5/PSL21267.jpg",
    "https://i.ibb.co/fkzzHYV/PSL21275.jpg",
    "https://i.ibb.co/2N3RVzG/PSL21276.jpg",
    "https://i.ibb.co/MRyR62D/PSL21280.jpg",
    "https://i.ibb.co/Tkq89rj/PSL21292.jpg",
    "https://i.ibb.co/Z296K5H/PSL21294.jpg",
    "https://i.ibb.co/1MTdD2q/PSL21299.jpg",
    "https://i.ibb.co/58jBh3P/PSL21313.jpg",
    "https://i.ibb.co/Jpnsd1g/PSL21322.jpg",
    "https://i.ibb.co/DRm8gGv/PSL21327.jpg",
    "https://i.ibb.co/85HmZnb/PSL21329.jpg",
    "https://i.ibb.co/fQzgMRC/PSL21334.jpg",
    "https://i.ibb.co/hm2RWf5/PSL21342.jpg",
    "https://i.ibb.co/0q9B3Ht/PSL21350.jpg",
    "https://i.ibb.co/k5tq0Fd/PSL21380.jpg",
    "https://i.ibb.co/Y3cKZrT/PSL21385.jpg",
    "https://i.ibb.co/JrfrJsN/PSL21390.jpg",
    "https://i.ibb.co/qW4kDpf/IMG-4706.jpg",
    "https://i.ibb.co/jZYcByv/DSCF0034.jpg",
    "https://i.ibb.co/Gp0WkkR/DSCF0043.jpg",
    "https://i.ibb.co/W2ymHdk/DSCF0045.jpg",
    "https://i.ibb.co/GC92gN7/DSCF0050.jpg",
    "https://i.ibb.co/n1PQ7Dp/DSCF0052.jpg",
    "https://i.ibb.co/bRSZX7N/DSCF0056.jpg",
    "https://i.ibb.co/YBS1pwg/DSCF0062.jpg",
    "https://i.ibb.co/nBj6Xmj/DSCF0064.jpg",
    "https://i.ibb.co/DLxY6Ln/DSCF0069.jpg",
    "https://i.ibb.co/rmy1pCB/DSCF0076.jpg",
    "https://i.ibb.co/P5FrFGr/DSCF0081.jpg",
    "https://i.ibb.co/89s60Wj/DSCF0083.jpg",
    "https://i.ibb.co/MZJXg0L/DSCF0090.jpg",
    "https://i.ibb.co/PDbgXsd/DSCF0098.jpg",
    "https://i.ibb.co/LNySpGh/DSCF0093.jpg",
    "https://i.ibb.co/TTphDjT/DSCF0100.jpg",
    "https://i.ibb.co/M1S0t1p/DSCF0104.jpg",
    "https://i.ibb.co/1bLW8bF/IMG-4854.jpg",
    "https://i.ibb.co/WW45kjH/PSL21400.jpg",
    "https://i.ibb.co/SBGvCVq/PSL21405.jpg",
    "https://i.ibb.co/NKPyzV7/PSL21415.jpg",
    "https://i.ibb.co/6gzQzKv/PSL21424.jpg",
    "https://i.ibb.co/BTcQQNt/PSL21429.jpg",
    "https://i.ibb.co/ccLYsSQ/PSL21432.jpg",
    "https://i.ibb.co/JQmGdXf/PSL21442.jpg",
    "https://i.ibb.co/xzbLDXv/PSL21447.jpg",
    "https://i.ibb.co/K6dGHYP/PSL21453.jpg",
    "https://i.ibb.co/y5tN74q/PSL21449.jpg",
    "https://i.ibb.co/S0JtsxR/PSL21458.jpg",
    "https://i.ibb.co/pwnfsSP/PSL21459.jpg",
    "https://i.ibb.co/V3S70xp/DSCF0105.jpg",
    "https://i.ibb.co/kMjn5zY/DSCF0151.jpg",
    "https://i.ibb.co/yFBzzqp/DSCF0107.jpg",
    "https://i.ibb.co/yBdFRqP/DSCF0114.jpg",
    "https://i.ibb.co/HnykG00/DSCF0120.jpg",
    "https://i.ibb.co/WkpDhNk/DSCF0123.jpg",
    "https://i.ibb.co/4KCXfRK/DSCF0125.jpg",
    "https://i.ibb.co/SKWtSxM/DSCF0127.jpg",
    "https://i.ibb.co/gRN0Gf8/DSCF0130.jpg",
    "https://i.ibb.co/KW5hVhy/DSCF0140.jpg",
    "https://i.ibb.co/TRf1Kv2/DSCF0143.jpg",
    "https://i.ibb.co/ZVH7YWP/DSCF0147.jpg",
    "https://i.ibb.co/xm295P7/IMG-4732.jpg",
    "https://i.ibb.co/s9PwTGS/PSL21460.jpg",
    "https://i.ibb.co/MMzcVvy/PSL21463.jpg",
    "https://i.ibb.co/kXcx6yN/PSL21466.jpg",
    "https://i.ibb.co/JkVstpB/PSL21471.jpg",
    "https://i.ibb.co/k5t4FMq/IMG-4735.jpg",
    "https://i.ibb.co/NF2JHqM/IMG-4738.jpg",
    "https://i.ibb.co/M2SvWsF/IMG-4750.jpg",
    "https://i.ibb.co/31nvvgM/IMG-4753.jpg",
    "https://i.ibb.co/N7pm4J8/IMG-4757.jpg",
    "https://i.ibb.co/Rv84yJg/IMG-4764.jpg",
    "https://i.ibb.co/d0LRMMc/IMG-4771.jpg",
    "https://i.ibb.co/0BRC2mY/IMG-4778.jpg",
    "https://i.ibb.co/ncKp7YD/IMG-4782.jpg",
    "https://i.ibb.co/GPrHsyt/IMG-4787.jpg",
    "https://i.ibb.co/Bq24vHF/IMG-4793.jpg",
    "https://i.ibb.co/wQwMgF0/IMG-4796.jpg",
    "https://i.ibb.co/CJWX2NS/IMG-4812.jpg",
    "https://i.ibb.co/ynBK35g/IMG-4818.jpg",
    "https://i.ibb.co/XVCTDxX/IMG-4825.jpg",
    "https://i.ibb.co/K9H6X0Q/IMG-4829.jpg",
    "https://i.ibb.co/3mMc2ND/IMG-4843.jpg",
    "https://i.ibb.co/rH5P8rF/IMG-4833.jpg",
    "https://i.ibb.co/gJmHf6d/IMG-4836.jpg",
    "https://i.ibb.co/LvyCRD2/IMG-4835.jpg",
    "https://i.ibb.co/2kThNR9/IMG-4838.jpg",
  ],
};

export default album;
