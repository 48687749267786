import React from "react";

import "./MyVideo.scss";

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded Youtube"
    />
  </div>
);

const MyVideo = () => {
  return (
    <section id="video" className="video">
      <YoutubeEmbed embedId="qOK6nxIpRSw" />
      <YoutubeEmbed embedId="lgUsiPyYBw4" />
    </section>
  );
};

export default MyVideo;
